import { action } from "mobx";
import React, { Suspense, useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "./pages/layout/LoadingPage";
import PageRouteLayout from "./pages/layout/PageLayout";

import RootContext from "./store/RootContext";
import UnderConst from "./pages/public/UnderConst";

const SIGNUP_ROUTE_SUFFIX = "signup";
const LOGIN_ROUTE_SUFFIX = "login";
const PROFILE_ROUTE_SUFFIX = "profile";
const USER_ROUTE_PREFIX = "/user/";

const ROUTES: { [k: string]: string } = {
  DEFAULT_LANDING_ROUTE: "/Paris2024",
  SIGNUP_ROUTE: `${USER_ROUTE_PREFIX}${SIGNUP_ROUTE_SUFFIX}`,
  LOGIN_ROUTE: `${USER_ROUTE_PREFIX}${LOGIN_ROUTE_SUFFIX}`,
  PROFILE_ROUTE: `${USER_ROUTE_PREFIX}${PROFILE_ROUTE_SUFFIX}`,
  PROFILE_EDIT_ROUTE: `${USER_ROUTE_PREFIX}${PROFILE_ROUTE_SUFFIX}/edit`,
  WELCOME_ROUTE: `/`,
  ASKATHLETS_ROUTE: `/ask-athlets/`,
  SURVEYS_ROUTE: `/surveys/`,
  CONTENTS_ROUTE: `/contents/`,
  ARTICLE_ROUTE: `/articles/`,
  ARTICLE_DETAIL_ROUTE: "/article/:category/:articleId/detail/",
  DUTY_ROUTE: `/duties/`,
  SUCCESSPATH_ROUTE: `/success-path/`,
  TEST_ROUTE: `/test-page/`,
  GIFT_ROUTE: `/gifts/`,
  LEADERBOARD_ROUTE: `/leader-board/`,
  COMINGSOON_ROUTE: "/coming-soon/",
  KVKK_ROUTE: "/aydinlatma-metni/",
  EARN_MONEY: "/earn-money/",
  CAMPAIGN_DETAIL: "/campaign-detail/:campaignId",
};

export { ROUTES };

const LoginPage = React.lazy(() => import("./pages/public/LoginPage"));
const ProfilePage = React.lazy(
  () => import("./pages/authorized/Profile/UserProfile")
);
const ProfileEditPage = React.lazy(
  () => import("./pages/authorized/Profile/UserProfileEdit")
);
const AskAtlets = React.lazy(() => import("./pages/authorized/AskAtlets"));
const Surveys = React.lazy(() => import("./pages/authorized/Surveys"));
const Contents = React.lazy(() => import("./pages/authorized/Contents"));
const Articles = React.lazy(() => import("./pages/authorized/Article"));
const ArticleDetail = React.lazy(
  () => import("./pages/authorized/ArticleDetail")
);
const Duties = React.lazy(() => import("./pages/authorized/Duties"));
const LeaderBoard = React.lazy(() => import("./pages/authorized/LeaderBoard"));
const Gifts = React.lazy(() => import("./pages/authorized/Gifts"));
const Test = React.lazy(() => import("./pages/public/Test"));
const SignupPage = React.lazy(() => import("./pages/public/SignupPage"));
const WelcomePage = React.lazy(() => import("./pages/public/WelcomePage"));
const ComingSoonPage = React.lazy(() => import("./pages/public/ComingSoon"));
const AydinlatmaMetni = React.lazy(() => import("./pages/public/AydinlatmaMetni"));
const ConstPage = React.lazy(() => import("./pages/public/UnderConst"));
const EarnMoney = React.lazy(() => import("./pages/authorized/EarnMoney"));
const CampaignDetail = React.lazy(() => import("./pages/authorized/CampaignDetail"));

const AppRoute = () => {
  const context = useContext(RootContext);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(
    action(() => {
      context.themeStore.init(location, navigate);
      context.themeStore.setRoutes(ROUTES);
    }),
    [location, ROUTES]
  );
  const isUserAuthenticated = context.identityStore.userHasValidSession;
  return (
    <UnderConst />
    /*
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/" element={<UnderConst />} />
      </Routes>
    </Suspense>
    */
  );
};

export default AppRoute;
