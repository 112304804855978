import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IQuestionService, { AskQuestionRequestWebDto, QuestionAnswerWebDto } from "../../../interface/app/IQuestionService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class QuestionRestService extends ServiceBase implements IQuestionService{
  /**
   *
   */
  constructor(identityStore:IdentityStore) {
    super(identityStore);
    
  }
  getQuestionsByCategory(categoryId: string): Observable<ServiceResponse<QuestionAnswerWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<any>>({
      url: `${Env.services.api.host}/API/Question/v7/GetLastAnsweredQuestions/${categoryId}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  askQuestion(request: AskQuestionRequestWebDto): Observable<ServiceResponse<any>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<any>>({
      url: `${Env.services.api.host}/API/Question/v7/Ask`,
      method: "POST",
      headers,
      body:request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getLastAnsweredQuestions(filter: PageFilter): Observable<ServiceResponse<QuestionAnswerWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<QuestionAnswerWebDto[]>>({
      url: `${Env.services.api.host}/API/Question/v7/GetLastAnsweredQuestions?${filter.toString()}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
}