import React, { useEffect } from "react";

const UnderConst = () => {
  useEffect(() => {
    // Belirtilen URL'ye yönlendirir
    window.location.href = "http://busstop.pg.com/";
  }, []);

  return (
    <div className="fullPage">
      <noscript>
        <meta http-equiv="refresh" content="0;url=http://busstop.pg.com" />
      </noscript>
    </div>
  );
};

export default UnderConst;
