import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IVideoService, { IsVideoWatchedWebDto, VideoCategoryWebDto, VideoWebDto, WatchVideoRequestWebDto } from "../../../interface/app/IVideoService";
import { ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class VideoRestService extends ServiceBase implements IVideoService{
  /**
   *
   */
  constructor(identityStore:IdentityStore) {
    super(identityStore);
    
  }
  isVideoWatched(videoId: string): Observable<ServiceResponse<IsVideoWatchedWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<IsVideoWatchedWebDto>>({
      url: `${Env.services.api.host}/API/Video/v7/IsWatched/${videoId}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  
  
  postWatchVideo(request: WatchVideoRequestWebDto): Observable<ServiceResponse<any>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<VideoCategoryWebDto[]>>({
      url: `${Env.services.api.host}/API/Video/v7/Watched`,
      method: "POST",
      headers,
      body:request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getVideoCategories(): Observable<ServiceResponse<VideoCategoryWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<VideoCategoryWebDto[]>>({
      url: `${Env.services.api.host}/API/Video/v7/GetCategories`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getCategoryVideos(categoryId: string): Observable<ServiceResponse<VideoWebDto[]>> {
    //
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<VideoWebDto[]>>({
      url: `${Env.services.api.host}/API/Video/v7/GetVideos/${categoryId}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getHomePageVideos(): Observable<ServiceResponse<VideoWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<VideoWebDto[]>>({
      url: `${Env.services.api.host}/API/Video/v7/GetVideosForHomePage`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
}