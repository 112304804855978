import { VideoCategoryWebDto, VideoWebDto } from "../../services/interface/app/IVideoService";
import { CategoryView, ContentView } from "../Model";


let VideoRestToViewModelMapping = (item:VideoWebDto,categoryId:string)=>{
  let questionView:ContentView={
    categoryId:categoryId,
    completed:item.isWatched,
    contentId:item.id,
    description:item.description,
    header: item.title,
    previewImgUrl: item.thumbnailURL,
    btnEnabled:false,
    superContent:false,
    altCattegoryType:item.categoryTitle,
    videoUrl: item.parameter
  }
  return questionView;
}


let VideoCategoryToViewModelMapping = (item:VideoCategoryWebDto):CategoryView=>{
  let questionView:CategoryView={
     contentType: "VIDEO",
     desc: item.description,
     header: item.title,
     id: item.id
  }
  return questionView;
}

export {VideoRestToViewModelMapping,VideoCategoryToViewModelMapping};