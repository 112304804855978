import dayjs from "dayjs";
import trLocale from "dayjs/locale/tr";
import { action } from "mobx";
import React, { useContext, useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./Routes";
import RootContext from "./store/RootContext";

import { Observable, fromEvent, startWith, switchMap, take } from "rxjs";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { Env } from "../env";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

dayjs.locale(trLocale);

let App: React.FC<{}> = (props) => {

  const context = useContext(RootContext);
  const { boot, shutdown, hide, show, update } = useIntercom();


  useEffect(
    action(() => {
      context.initApplication({boot, shutdown, hide, show, update});
    })
    ,
    []
  );
  return (
    <RootContext.Provider value={context}>
      <AppRoute></AppRoute>
    </RootContext.Provider>
  );
};
const AppWithRouter = () => (
  <BrowserRouter>
  <IntercomProvider autoBoot={false} appId={Env.marketing.intercom.app_id}>
    <App />
  </IntercomProvider>
  </BrowserRouter>
);
export default AppWithRouter;