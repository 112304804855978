import {
  computed,
  extendObservable,
  makeAutoObservable,
  makeObservable,
} from "mobx";
import FormInputModel from "../FormInputModel";
import IFormInputModel, { FormInputs } from "../IFormInputModel";

class SignUpModel extends FormInputModel implements IFormInputModel {
  private static genderData= [
    { value: "Erkek", id: "1" },
    { value: "Kadın", id: "2" },
 
  ];
  constructor() {
    super({
      fieldKeys: [
        "firstName",
        "lastName",
        "email",
        "password",
        "passwordr",
        "gender",
        "bdate",
        "kvkk",
        "agreement",
        "adimadimkazan_aggrement",
      ],
      formLabels: {
        data: {
          gender: SignUpModel.genderData,
        },
        requiredKeys:{
          firstName:"İsim alanı boş bırakılamaz",
          lastName:"Soyad alanı boş bırakılamaz",
          email:"Email alanı boş bırakılamaz",
          password:"Şifre alanı boş bırakılamaz",
          gender:"Cinsiyet bilgisi boş bırakılamaz",
          bdate:"Doğum tarihi boş bırakılamaz",
          //agreement:"Sözleşme metin onayı gereklidir",
          adimadimkazan_aggrement:"Kullanım koşulları onayı gerekmektedir.",
        },
        validation: {
          
          firstName: (value: string) => {
            let errors: string[] = [];
            if (value.length < 3) {
              errors.push("İsim en az 3 karakter olmalıdır.");
            }
            return { invalid: errors.length > 0, errors };
          },
          lastName: (value: string) => {
            let errors: string[] = [];
            if (value.length < 2) {
              errors.push("Soyad en az 2 karakter olmalıdır.");
            }
            return { invalid: errors.length > 0, errors };
          },
          email: (value: string) => {
            let errors: string[] = [];
            let emailRegexp = new RegExp(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            if (emailRegexp.exec(value) == null) {
              errors.push("Geçersiz email adresi");
            }
            return { invalid: errors.length > 0, errors };
          },
          adimadimkazan_aggrement: (value: boolean) => {
            
            let errors: string[] = [];
            
            if (value === false) {
              errors.push("Kullanım koşulları onayı gerekmektedir.");
            }
            return { invalid: errors.length > 0, errors };
            
          },
          bdate: (value: Date) => {
            let errors: string[] = [];
            let yearDiff = new Date().getFullYear() - value.getFullYear();
              if (yearDiff < 16 || yearDiff > 100) {
                errors.push("Lütfen geçerli bir doğum tarihi giriniz.");
              }
  
            return { invalid: errors.length > 0, errors };
          },
          password: (value: string, form: FormInputs) => {
            let errors: string[] = [];
            if (value.length < 8) {
              errors.push("Şifreniz en az 8 karakter olmalıdır");
            }
            if (value && value !== form["passwordr"]) {
              errors.push("Şifreler birbirleri ile uyuşmuyor.");
            }
            return { invalid: errors.length > 0, errors };
          },
          gender: (value: { value: string; id: string }) => {
            let errors: string[] = [];
            if (SignUpModel.genderData.filter((b) => b.id == value?.id).length === 0) {
              errors.push("Cinsiyet Boş Bırakılamaz");
            }
            return { invalid: errors.length > 0, errors };
          },
        },
      },
      defaultFormInputValues: {
        adimadimkazan_aggrement:false,
        kvkk:false,
        agreement:false,
        gender: { value: "NA", id: "3" },
      },
    });
    this.initForm();
  }

  


}

export default SignUpModel;
