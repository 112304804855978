import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IGiftService, {
  GiftLevelDetailWebDto,
  GiftLevelMetaWebDto,
  ReservationCompleteRequestWebDto,
  ReservationCompleteResponseDto,
  ReservationCompleteWithAddressRequestWebDto,
  ReservationCompleteWithAddressResponseDto,
  ReservationRequestWebDto,
  ReservationResponseWebDto,
} from "../../../interface/app/IGiftService";
import { ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class GiftRestService
  extends ServiceBase
  implements IGiftService
{
  /**
   *
   */
  constructor(identityStore:IdentityStore) {
    super(identityStore);
    
  }
  startReservation(request: ReservationRequestWebDto): Observable<ServiceResponse<ReservationResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<ReservationResponseWebDto>>({
      url: `${Env.services.api.host}/API/Gift/v7/Reservation`,
      method: "POST",
      headers,
      body:request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  completeReservation(request: ReservationCompleteRequestWebDto): Observable<ServiceResponse<ReservationCompleteResponseDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<ReservationCompleteResponseDto>>({
      url: `${Env.services.api.host}/API/Gift/v7/CompleteReservation`,
      method: "POST",
      headers,
      body:request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  completeReservationWithAddress(request: ReservationCompleteWithAddressRequestWebDto): Observable<ServiceResponse<ReservationCompleteWithAddressResponseDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<ReservationCompleteWithAddressResponseDto>>({
      url: `${Env.services.api.host}/API/Gift/v7/CompleteReservationWithAddress`,
      method: "POST",
      headers,
      body:request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getLevelDetails(levelId: string): Observable<ServiceResponse<GiftLevelDetailWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<GiftLevelDetailWebDto>>({
      url: `${Env.services.api.host}/API/Gift/v7/GetGiftsByLevel/${levelId}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getLevelsMeta(): Observable<ServiceResponse<GiftLevelMetaWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<GiftLevelMetaWebDto[]>>({
      url: `${Env.services.api.host}/API/Gift/v7/GetLevels`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
}
