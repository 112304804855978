import React from "react";
import ActivityStore from "./app/ActivityStore";
import ArticleStore from "./app/ArticleStore";
import CategoryAthletStore from "./app/CategoryAthletStore";
import ContentVideoStore from "./app/ContentVideoStore";
import RewardStore from "./app/RewardStore";
import GiftStore from "./app/GiftStore";
import LeaderStore from "./app/LeaderStore";
import MarketingAutomation from "./app/MarketingAutomation";
import QuestionStore from "./app/QuestionStore";
import SurveyStore from "./app/SurveyStore";
import UserStore from "./app/UserStore";
import ThemeStore from "./ThemeStore";
import IdentityStore from "./user/IdentityStore";
import EarnMoneyStore from "./app/EarnMoneyStore";

type APP_ROUTES = { [k: string]: string };
class RootContextWrapper {
  themeStore: ThemeStore;
  identityStore: IdentityStore;
  articleStore: ArticleStore;
  surveyStore: SurveyStore;
  activityStore: ActivityStore;
  userStore: UserStore;
  giftStore: GiftStore;
  questionStore: QuestionStore;
  categoryAthletStore: CategoryAthletStore;
  contentVideoStore: ContentVideoStore;
  leaderStore: LeaderStore;
  marketingAutomation: MarketingAutomation;
  rewardStore:RewardStore;
  earnMoneyStore:EarnMoneyStore;
  intercomEvents:any;
  constructor() {
    this.identityStore = new IdentityStore(this);
    this.themeStore = new ThemeStore(this);
    this.articleStore = new ArticleStore(this);
    this.surveyStore = new SurveyStore(this);
    this.activityStore = new ActivityStore(this);
    this.userStore = new UserStore(this);
    this.giftStore = new GiftStore(this);
    this.questionStore = new QuestionStore(this);
    this.categoryAthletStore = new CategoryAthletStore(this);
    this.contentVideoStore = new ContentVideoStore(this);
    this.leaderStore = new LeaderStore(this);
    this.marketingAutomation = new MarketingAutomation(this);
    this.rewardStore = new RewardStore(this);
    this.earnMoneyStore = new EarnMoneyStore(this);
    this.intercomEvents = {};
  }

  public initApplication(props:any) {
    this.articleStore.initStore();
    this.userStore.loadStore();
    this.activityStore.loadStore();
    this.questionStore.loadStore();
    this.categoryAthletStore.loadStore();
    this.contentVideoStore.loadStore();
    this.leaderStore.loadStore();
    this.giftStore.loadStore();
    this.identityStore.loadStore();
    //this.marketingAutomation.initStore();
    this.rewardStore.loadStore();
    this.intercomEvents = props;
  }
}

const RootContext = React.createContext<RootContextWrapper>(
  new RootContextWrapper()
);

export default RootContext;

export type { APP_ROUTES, RootContextWrapper };
