type Environment = {
  services: {
    auth: {
      host: string;
      client: {
        secret: string;
        id: string;
      };
      ropc: {
        id: string;
        secret: string;
        refreshTokenExpiresIn: number;
      };
      GooglRv3Key: "";
    };
    api: {
      host: string;
    };
  };
  gifts: {
    guideFilePdfUrl: string;
    conditionsFilePdfUrl: string;
  };
  marketing: {
    intercom: {
      app_id: string;
      api_base: string;
    };
  };
};

const {
  SERVICE_AUTH_HOST,
  SERVICE_CLIENT_ID,
  SERVICE_CLIENT_SECRET,
  SERVICE_ROPC_CLIENT_ID,
  SERVICE_ROPC_CLIENT_SECRET,
  SERVICE_ROPC_REFRESHTOKEN_EXPIRES_IN,
  SERVICE_API_HOST,
  GIFTS_GUID_FILE,
  GIFTS_COND_FILE,
  INTERCOM_APP_ID,
  INTERCOM_API_BASE,
  GOOGLE_RECAPTCHAKEY,
} = (window as any).__env__;

type pgEvent = {
  event: string;
  GAeventCategory: string;
  GAeventAction: string;
  GAeventLabel: string;
  GAeventValue: any;
  GAeventNonInteraction: boolean;
};
export const pgEventPush = (event: pgEvent) => {
  const PgDataLayer = (window as any).dataLayer;

  if (PgDataLayer) {
    PgDataLayer.push(event);
  }
};

export const Env: Environment = {
  services: {
    auth: {
      host: SERVICE_AUTH_HOST,
      client: {
        secret: SERVICE_CLIENT_SECRET,
        id: SERVICE_CLIENT_ID,
      },
      ropc: {
        id: SERVICE_ROPC_CLIENT_ID,
        secret: SERVICE_ROPC_CLIENT_SECRET,
        refreshTokenExpiresIn: SERVICE_ROPC_REFRESHTOKEN_EXPIRES_IN,
      },
      GooglRv3Key: GOOGLE_RECAPTCHAKEY,
    },
    api: {
      host: SERVICE_API_HOST,
    },
  },
  gifts: {
    conditionsFilePdfUrl: GIFTS_COND_FILE,
    guideFilePdfUrl: GIFTS_GUID_FILE,
  },
  marketing: {
    intercom: {
      app_id: INTERCOM_APP_ID,
      api_base: INTERCOM_API_BASE,
    },
  },
};
