import { triggerAsyncId } from "async_hooks";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { CategoryPageContentView, ContentView } from "../../model/Model";
import { ArticleDetailView } from "../../model/view/article/ArticleDetailView";
import ArticleRestService from "../../services/implementation/rest/app/ArticleRestService";
import IArticleService, { ArticleDetailWebDto, ArticleWebDto } from "../../services/interface/app/IArticleService";
import { RootContextWrapper } from "../RootContext";

export default class ArticleStore {
  articlePageContent: CategoryPageContentView = {
    category: [
      {
        id: "CAT1",
        contentType: "ARTICLE",
        desc: "Uzmanların sizin için hazırladığı içerikler, sporculardan kazandıran ipuçları ve çok daha fazlası burada!",
        header: "Eğitici İçerikler",
      },
    ],
    contents: [],
  };

  articleDetailPageContent: ArticleDetailView = {
    articleTitle: "",
    categoryText: "",
    publishDate: "",
    imageUrl: "",
    mdContent: "",
  };

  articleService: IArticleService = new ArticleRestService(
    this.rootContext.identityStore
  );

  constructor(private rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  loadArticles() {
    this.articleService
      .getArticles({ page: 1, take: 50 })
      .subscribe((response) => {
        this.articlePageContent.contents = (response.data as ArticleWebDto[]).map((a) => {
          return {
            categoryId: "CAT1",
            completed: a?.didGetPoints,
            btnEnabled: true,
            contentId: a?.id,
            description: a?.description,
            header: a?.title,
            altCattegoryType: a?.categoryTitle,
            previewImgUrl: a.photoURL
          } as ContentView;
        });
      });
  }

  loadArticleDetail(articleId?: string, category?: string) {
    if (articleId) {
      this.articleService.getArticleDetail(articleId).subscribe((a) => {
        let detail = a.data as ArticleDetailWebDto;
        this.articleDetailPageContent = {
          articleTitle: detail.title ?? "",
          categoryText: category ?? "",
          imageUrl: detail.photoURL,
          mdContent: detail.content,
          publishDate: dayjs(detail.publishDate).format('DD MMMM YYYY').toString(),
        };
      });
    }
  }

  initStore() {}
}
