import { QuestionAnswerWebDto } from "../../services/interface/app/IQuestionService";
import { QuestionAnswerView } from "../view/question/Question";

let QuestionRestToViewModel = (qa:QuestionAnswerWebDto)=>{
  let questionView:QuestionAnswerView={
    asnwer:{
      answer:qa.answer,
      questionId:qa.questionID,
      replierId:qa.athleteName,
      userName:qa.athleteName,
      photoUrl: qa.athletePhotoURL
    },
    question:{
      categoryId:qa.questionCategoryID,
      question:qa.question,
      questionerId: qa.askerName,
      questionId: qa.athletePhotoURL,
      userName: qa.askerName,
      photoUrl: qa.askerPhotoURL,
    }
  }
  return questionView;
}

export {QuestionRestToViewModel};