import FormInputModel from "../FormInputModel";
import IFormInputModel from "../IFormInputModel";

export const PROFILE_GENDERS = [
  { value: "Erkek", id: 0 },
  { value: "Kadın", id: 1 },
];

const getProfileGenderById = (id:number):string=>{
  return PROFILE_GENDERS.filter(a=> a.id===id)[0]?.value??"";
}

export {getProfileGenderById};

class ProfilEditFormModel extends FormInputModel implements IFormInputModel{
  private static genderData= PROFILE_GENDERS;
  emptyValidator=(value:string,keyName:string)=>{
    let errors: string[] = [];
            if (value.trim().length < 2) {
              errors.push(`${keyName} alanı boş bırakılamaz`);
            }
    return { invalid: errors.length > 0, errors };
  }
  constructor() {
    super({
      defaultFormInputValues:{},
      fieldKeys:[
        "firstName",
        "lastName",
        "gender",
        "birthdate",
        "city",
        "district",
        "neighborhood",
        "address"
      ],
      formLabels:{
        data: {
          gender: ProfilEditFormModel.genderData,
        },
        requiredKeys:{
          "firstName":"Ad alanı boş bırakılamaz",
          "lastName":"Soyad alanı boş bırakılamaz",
          "gender":"Cinsiyet alanı boş bırakılamaz",
          "birthdate":"Doğum tarihi boş bırakılamaz",
          "city":"Şehir alanı boş bırakılamaz",
          "district":"İlçe alanı boş bırakılamaz",
          "address":"Adres alanı boş bırakılamaz",
        },
        validation:{
          firstName:(v)=>this.emptyValidator(v,"firstName"),
          lastName:(v)=>this.emptyValidator(v,"lastName"),
          city:(v)=>this.emptyValidator(v,"city"),
          district:(v)=>this.emptyValidator(v,"district"),
          address:(v)=>this.emptyValidator(v,"address"),
          gender: (value: { value: string; id: string }) => {
            let errors: string[] = [];
            if (ProfilEditFormModel.genderData.filter((b) => b.id.toString() == value?.id).length === 0) {
              errors.push("Cinsiye Boş Bırakılamaz");
            }
            return { invalid: errors.length > 0, errors };
          },
          birthdate: (value: Date) => {
            let errors: string[] = [];
            let yearDiff = new Date().getFullYear() - value.getFullYear();
              if (yearDiff < 10 || yearDiff > 100) {
                errors.push("Lütfen geçerli bir doğum tarihi giriniz.");
              }
            return { invalid: errors.length > 0, errors };
          },
        }
      }
    });
    this.initForm();
    
  }
}

export default ProfilEditFormModel;