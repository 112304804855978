import dayjs, { Dayjs } from "dayjs";
import jwtDecode from "jwt-decode";
import { Observable } from "rxjs";
import { Env } from "../../../env";
import { ServiceResponse } from "./IServiceBase";

export type UserRegisterDto = {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    birthDate: Dayjs;
    gender: 0 | 1;
    isCheckClarificationText: boolean; //optinstatus
    isCheckTermsOfUse: boolean;
    agreementLast:boolean;
  };
  password: string;
  //recaptchaToken :string;
  //recaptchaAction:string;
};

export class TokenBase {
  access_token?: string;
  expires_in: number = 0;
  token_type: string = "";
  scope?: string;
  refresh_token?: string;
  auth_time: dayjs.Dayjs;
  /**
   *
   */
  constructor(data?: Partial<TokenBase>) {
    Object.assign(this, { ...this, ...data });
    var authTime = dayjs(new Date());
    if(data?.access_token){
      let parsedToken = this.parseToken() as any;
      if(parsedToken){
        authTime = dayjs.unix(parsedToken.auth_time);
      }
    }
    this.auth_time = data?.access_token
      ? authTime
      : dayjs(new Date(1970));
  }

  parseToken(): undefined | object {
    if (this.access_token) return jwtDecode(this.access_token);
    return undefined;
  }
}

export class ClientTokenStorageModel extends TokenBase {
  constructor(data?: Partial<ClientTokenStorageModel>) {
    super(data);
    this.expiresAt = dayjs(this.auth_time)
      .add(this.expires_in ?? 0, "seconds")
      .subtract(10, "seconds");
  }
  expiresAt?: dayjs.Dayjs;
}
export class UserRopcToken extends ClientTokenStorageModel {
  /**
   *
   */
  constructor(data?: Partial<UserRopcToken>) {
    super(data);
    this.refreshTokenExpiresAt = dayjs(this.auth_time)
      .add(Env.services.auth.ropc.refreshTokenExpiresIn, "seconds")
      .subtract(10, "seconds");
  }
  refreshTokenExpiresAt?: dayjs.Dayjs;
}

interface IAuthService {
  getClientCredentialsToken: () => Observable<
    ServiceResponse<ClientTokenStorageModel>
  >;
  refreshUserToken: () => Observable<ServiceResponse<UserRopcToken>>;
  loginWithPasswordCredentials: (
    username: string,
    pw: string
  ) => Observable<ServiceResponse<TokenBase>>;
  protectedLogin: (
    username: string,
    pw: string
  ) => Observable<ServiceResponse<UserRopcToken>>;
  registerUser(
    request: UserRegisterDto
  ): Observable<ServiceResponse<UserRegisterDto>>;
  registerProtectedUser(
    request: UserRegisterDto
  ): Observable<ServiceResponse<UserRegisterDto>>;

  notifyLoginApplicationUser(): Observable<ServiceResponse<any>>;
}

export default IAuthService;
