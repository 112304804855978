import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IAthletService, { AthletCategoryWebDto, AthletWebDto } from "../../../interface/app/IAthletService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class AthletRestService extends ServiceBase implements IAthletService{
  /**
   *
   */
  constructor(identityStore:IdentityStore) {
    super(identityStore);
  }
  getAthletCategories(filter?: PageFilter): Observable<ServiceResponse<AthletCategoryWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<AthletCategoryWebDto[]>>({
      url: `${Env.services.api.host}/API/Athlete/v7/GetCategories?${filter?.toString()}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getAthletsByCategory(categoryId:string,filter: PageFilter): Observable<ServiceResponse<AthletWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<AthletWebDto[]>>({
      url: `${Env.services.api.host}/API/Athlete/v7/GetAthletes/${categoryId}?Page=${filter.page}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  
}