import {
    IReactionDisposer,
    makeAutoObservable,
    reaction,
    runInAction,
  } from "mobx";
  import {
    filter,
    forkJoin,
    map,
    mergeMap,
    of,
    Subject,
    Subscription,
    tap,
    throttleTime,
  } from "rxjs";
import { RootContextWrapper } from "../RootContext";
import EarnMoneyRestService from "../../services/implementation/rest/app/EarnMoneyRestService";
import IEarnMoneyService, { CampaignMoneyEarnDto, CampaignDetailWebDto, FisPostWebRequest, FisPostWebResponse, DbFileModel, FinishFileUploadRequest, FinishFileUploadResponse, HistoryListDto } from "../../services/interface/app/IEarnMoneyService";
import { CategoryPageContentView, ContentView } from "../../model/Model";
import { PageFilter, ServiceResponse } from "../../services/interface/IServiceBase";
import { CampaignDetailView } from "../../model/view/campaign/CampaignDetailView";
import { Accept } from "react-dropzone";
import { PROCESS_STATE } from "../user/IdentityStore";

export default class EarnMoneyStore {

    private _earnMoneyService: IEarnMoneyService;
    private _identityStore: import("../user/IdentityStore").default;
    uploadedFiles: File[] = [];
    fileRejections: [] = [];
    dropzoneFileText="";
    processState = PROCESS_STATE.NA;
    uploadButtonState = PROCESS_STATE.NA;
    chkState = false;
    modalOpenState=false;
    allDbFilesLinks:DbFileModel[] = [];
    leftActive:boolean = false;
    rightActive:boolean = false;
    showImageModal:boolean = false;
    imageUrl:string = "";
    historyList:HistoryListDto = {
      items:[]
    };
    pageFilter:PageFilter = {
      page:1,
      take:5
    }

    acceptedFileTypes: Accept= {
        'image/jpeg': [],
        'image/png': [],
        'application/pdf': [],
    };

    campaignDetailDto:CampaignMoneyEarnDto = {
        campaignID: "",
        shortDescription: "",
        title: "",
        photoURL: "",
        longDescription: "",
        weeklyLimit:0
    };
    earnMoneyData: CampaignMoneyEarnDto[] = [];
    earnMoneyPageContent: CategoryPageContentView = {
        category: [
          {
            id: "CAT1",
            contentType: "CAMPAIGN",
            desc: "Aktif Kampanyalar",
            header: "",
            btnText:"Fiş Tara",
          },
        ],
        contents: [],
      };

      campaignDetailPageContent: CampaignDetailView = {
        canUploadFileFromPhoto: true,
        canUploadFileFromDocument: true,
        fileSizeLimit: 0,
        fileHowManyMin:0,
        fileHowManyMax:0,
        photoQuality:0,
        supportedFileTypes:[],
        fileGroupID:""
      };

      fileUploadResult:FisPostWebResponse={
        isSuccess:false,
        message:"",
        previewImage:"",
        allImages:[""]
      }

      finishFileUploadResult:FinishFileUploadResponse={
        isSuccess:false,
        message:""
      }

    constructor(context: RootContextWrapper) {
        makeAutoObservable(this, {}, { autoBind: true });
        this._earnMoneyService = new EarnMoneyRestService(context.identityStore);
        this._identityStore = context.identityStore;
    }

    showImage(imageUrl:string){
      this.imageUrl = imageUrl;
      this.showImageModal = true;
    }

    closeImageModal(){
      this.imageUrl = "";
      this.showImageModal = false;
    }

    appendDbFiles(files:DbFileModel[]){
      runInAction(()=>{
        this.allDbFilesLinks = this.allDbFilesLinks.concat(files);
      })
    }

    loadEarnings() {
        return this._earnMoneyService
          .getAllEarning()
          .pipe(
            map((response) => response.data as CampaignMoneyEarnDto[]),
            map((data) => {
              return [data, data.map((item) => {
                return {
                  categoryId:"CAT1",
                  completed:false,
                  contentId:item.campaignID,
                  description:item.shortDescription,
                  header: item.title,
                  previewImgUrl: item.photoURL,
                  btnEnabled:false,
                  superContent:false,
                  altCattegoryType:"",
                  videoUrl: "",
                  hideStatusIcon:true
                }
              })]
            })
          )
          .subscribe((response) => {
            runInAction(() => {
              this.earnMoneyData=response[0] as CampaignMoneyEarnDto[];
              this.earnMoneyPageContent.contents = response[1] as ContentView[];
            });
          })
    }

    
  getCampaignDetail(campaignId?: string) {
    if (campaignId) {
      this._earnMoneyService.getCampaignDetail(campaignId).subscribe((a) => {
        runInAction(() => {
        let detail = a.data as CampaignDetailWebDto;
        this.campaignDetailPageContent = {
            canUploadFileFromPhoto: detail.canUploadFileFromPhoto,
            canUploadFileFromDocument: detail.canUploadFileFromDocument,
            fileSizeLimit: detail.fileSizeLimit,
            fileHowManyMin:detail.fileHowManyMin,
            fileHowManyMax:detail.fileHowManyMax,
            photoQuality:detail.photoQuality,
            supportedFileTypes:detail.supportedFileTypes,
            fileGroupID:detail.fileGroupID
        };
        });
      });
    }
  }

  fileUpload(request: FisPostWebRequest, giftCampaignID:string, groupID:string) {
    runInAction(() => {
      this.uploadButtonState = PROCESS_STATE.AWAITING;
    })
    return this._earnMoneyService
      .fileUpload(request, giftCampaignID, groupID).then((response) => {
        runInAction(() => {
          this.setUploadFiles([]);
          this.uploadButtonState = PROCESS_STATE.DONE;
          this.fileUploadResult = response as FisPostWebResponse;
          this.fileUploadResult.allImages.forEach((item) => {
            this.allDbFilesLinks.push({
              id: item,
              name: item,
              url: item
            })
          })

        });
      })
  }

  finishFileUpload(request: FinishFileUploadRequest) {
    runInAction(() => {
      this.uploadButtonState = PROCESS_STATE.AWAITING;
    })
   this._earnMoneyService
      .finishFileUpload(request)
      .pipe(map((response) => response.data as FinishFileUploadResponse)).subscribe((response) => {
        runInAction(() => {
          this.uploadButtonState = PROCESS_STATE.DONE;
          this.finishFileUploadResult = response;
          this.openSuccessPopup();
        });
      });
  }

  closeSuccessPopup() {
    runInAction(() => {
      this.modalOpenState = false;
    });
  }

  resetFileZone() {
    runInAction(() => {
      this.uploadedFiles = [];
    });
  }

  resetDbFileZone() {
    runInAction(() => {
      this.allDbFilesLinks = [];
    });
  }

  setUploadFiles(files: File[]) {
    runInAction(() => {
      this.uploadedFiles = files;
    });
  }

  setDropzoneFileText(text: string) {
    runInAction(() => {
      this.dropzoneFileText = text;
    });
  }

  setAcceptedFiles(accept: Accept) {
    runInAction(() => {
      this.acceptedFileTypes = accept;
    });
  }

  setFileRejections(fileRejections: []) {
    runInAction(() => {
      this.fileRejections = fileRejections;
    });
  }

  isUploadButtonDisabled() {
    return this.uploadButtonState == PROCESS_STATE.AWAITING;
  }

  resetForm() {
    runInAction(() => {
      this.resetFileZone();
      this.resetDbFileZone();
      this.setFileRejections([]);
      this.processState = PROCESS_STATE.NA;
      this.chkState = false;
      this.fileUploadResult={
        isSuccess:false,
        message:"",
        previewImage:"",
        allImages:[""]
      };
      this.setChkState(false);
    });
  }

  getButtonState() {
    if (this.allDbFilesLinks.length > 0 && this.chkState &&
      (this.processState === PROCESS_STATE.DONE || this.processState === PROCESS_STATE.NA)) {
      return true;
    }
    return false;
  }

  setChkState(state: boolean) {
    runInAction(() => {
      this.chkState = state;
    });
  }

  openSuccessPopup() {
    runInAction(() => {
      this.modalOpenState = true;
    });
  }

  getHistoyList() {
    runInAction(() => {
      this._earnMoneyService
      .getCollectedFises(this.pageFilter)
      .pipe(map((response) => response.data as HistoryListDto)).subscribe((response) => {
        runInAction(() => {
          this.historyList = response;
          if(this.historyList && this.historyList.items.length >= this.pageFilter.take){
              this.rightActive = true;
          }else{
            this.rightActive = false;
          }
          if(this.pageFilter.page == 1){
            this.leftActive = false;
          }
        });
      });
    });
  }

   onClickPrev = () => {
    runInAction(() => {
      if(this.pageFilter.page == 1){
        this.leftActive = false;
        return;
      }
      this.pageFilter.page = this.pageFilter.page - 1;
      this.getHistoyList();
    })
  };

   onClickNext =() => {
    runInAction(() => {
      if(!this.rightActive){
          return;
      }
      if(this.pageFilter.page > 1){
        this.leftActive = true;
      }
      this.pageFilter.page = this.pageFilter.page + 1;
      this.getHistoyList();
    })
   }




  initStore() {}

}