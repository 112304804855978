import { action, makeAutoObservable, reaction } from "mobx";
import { Location, NavigateFunction } from "react-router-dom";
import { ROUTES } from "../Routes";
import { APP_ROUTES, RootContextWrapper } from "./RootContext";
import IdentityStore from "./user/IdentityStore";

const BG_PRIMARY = "bg-gold-yellow-500";
const BG_WHITE = "bg-white";

type LayoutPage = {
  renderPageStaticBgImages: boolean;
  outletColor: string;
};

const defaulLayoutPage: LayoutPage = {
  renderPageStaticBgImages: false,
  outletColor: BG_WHITE,
};

export type MenuItem = {
  name: string;
  route: string;
  active: boolean;
  submenuItems: MenuItem[];
  isAuthRequiredToBeVisible: boolean;
  navigationType: "Router" | "ByAction";
  actionCallback?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

class ThemeStore {
  private _layoutPage: LayoutPage = defaulLayoutPage;
  locationRef: Location | undefined;
  mobileMenuOpen: boolean = false;
  private _rootContextStore: RootContextWrapper;
  ROUTES: APP_ROUTES = {};
  faqModalAction: () => void;
  private readonly _identityStore: IdentityStore;
  navigate?: NavigateFunction;
  /**
   *
   */
  constructor(rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._rootContextStore = rootContext;
    this._identityStore = rootContext.identityStore;
    reaction(
      () => this.locationRef?.key,
      () => {
        if (this.locationRef) this.updateRenderPageStatus(this.locationRef);
        rootContext.userStore.loadUserScore();
      }
    );
    this.faqModalAction = () => {
      rootContext.activityStore.openFaqModal();
      rootContext.themeStore.mobileMenuOpen = false;
    };
    // Auto close mobileMenu
    reaction(
      () => this.locationRef?.pathname,
      (curr, prev) => {
        if (prev && curr && prev !== curr) {
          this.mobileMenuOpen = false;
        }
      }
    );
  }
  private updateRenderPageStatus(location: Location) {
    if (
      location.pathname === this.ROUTES.LOGIN_ROUTE ||
      location.pathname === this.ROUTES.SIGNUP_ROUTE
    ) {
      this._layoutPage = {
        ...defaulLayoutPage,
        renderPageStaticBgImages: true,
        outletColor: BG_PRIMARY,
      };
    } else {
      this._layoutPage = defaulLayoutPage;
    }

    if (location.pathname === this.ROUTES.GIFT_ROUTE) {
      this._layoutPage = {
        ...defaulLayoutPage,
        renderPageStaticBgImages: false,
        outletColor: BG_PRIMARY,
      };
    }
  }

  private getMenuItems(): MenuItem[] {
    let data: MenuItem[] = [
      {
        active: false,
        route: this.ROUTES.SUCCESSPATH_ROUTE,
        name: "Madalya Yolculuğu",
        submenuItems: [],
        isAuthRequiredToBeVisible: false,
        navigationType: "Router",
      },
      {
        active: false,
        route: this.ROUTES.CONTENTS_ROUTE,
        name: "Görevler",
        navigationType: "Router",
        isAuthRequiredToBeVisible: false,
        submenuItems: [
          {
            name: "Davet Et Kazan", navigationType: "ByAction", isAuthRequiredToBeVisible: false, route: "#davetetkazan",
            active: false, submenuItems: [], actionCallback: action(() => {
              if(!this._identityStore.isUserAuthenticated){
                this.navigate && this.navigate(this.ROUTES.LOGIN_ROUTE);
                return;
              }
              this._rootContextStore.activityStore.openFriendReferralModal();;
            })
          },
          {
            active: false,
            route: this.ROUTES.SURVEYS_ROUTE,
            name: "Anketler",
            submenuItems: [],
            isAuthRequiredToBeVisible: false,
            navigationType: "Router",
          },
          {
            active: false,
            route: this.ROUTES.ARTICLE_ROUTE,
            name: "Makaleler",
            submenuItems: [],
            isAuthRequiredToBeVisible: false,
            navigationType: "Router",
          },
          {
            active: false,
            route: this.ROUTES.CONTENTS_ROUTE,
            name: "Harekete Geç",
            submenuItems: [],
            isAuthRequiredToBeVisible: false,
            navigationType: "Router",
          },
          {
            active: false,
            route: this.ROUTES.ASKATHLETS_ROUTE,
            name: "Sporculara Sor",
            submenuItems: [],
            isAuthRequiredToBeVisible: false,
            navigationType: "Router",
          },
        ],
      },
      {
        active: false,
        route: this.ROUTES.LEADERBOARD_ROUTE,
        name: "Lider Tablosu",
        isAuthRequiredToBeVisible: false,
        submenuItems: [],
        navigationType: "Router",
      },
      {
        active: false,
        route: this.ROUTES.GIFT_ROUTE,
        name: "Ödüller",
        isAuthRequiredToBeVisible: false,
        submenuItems: [],
        navigationType: "Router",
      },
      {
        active: false,
        route: "#al-kazan",
        name: "Al-Kazan",
        navigationType: "ByAction",
        isAuthRequiredToBeVisible: false,
        actionCallback: action(() => {
          if(!this._identityStore.isUserAuthenticated){
            this.navigate && this.navigate(this.ROUTES.LOGIN_ROUTE);
            return;
          }
          this._rootContextStore.rewardStore.fetchCampaignList();
          this._rootContextStore.rewardStore.openCampainModal();
          this._rootContextStore.themeStore.setMobileMenuState(false);
        }),
        submenuItems: [
          {
            name: "Kod Gir", navigationType: "ByAction", isAuthRequiredToBeVisible: false, route: "#kodgir",
            active: false, submenuItems: [], actionCallback: action(() => {
              if(!this._identityStore.isUserAuthenticated){
                this.navigate && this.navigate(this.ROUTES.LOGIN_ROUTE);
                return;
              }
              this._rootContextStore.rewardStore.openEnterCouponModal(true);
              this._rootContextStore.themeStore.setMobileMenuState(false);
            })
          },
          {
            name: "Kampanyalar", navigationType: "ByAction", isAuthRequiredToBeVisible: false, route: "#kampanyalar",
            active: false, submenuItems: [], actionCallback: action(() => {
              this._rootContextStore.rewardStore.fetchCampaignList();
              this._rootContextStore.rewardStore.openCampainModal();
              this._rootContextStore.themeStore.setMobileMenuState(false);
            })
          },
          {
            name: "Kuponlarım", navigationType: "ByAction", isAuthRequiredToBeVisible: false, route: "#kuponlarım",
            active: false, submenuItems: [], actionCallback: action(() => {
              if(!this._identityStore.isUserAuthenticated){
                this.navigate && this.navigate(this.ROUTES.LOGIN_ROUTE);
                return;
              }
              this._rootContextStore.rewardStore.fetchCouponList();
              this._rootContextStore.rewardStore.openCouponListModal();
              this._rootContextStore.themeStore.setMobileMenuState(false);
            })
          },
          /*
          {
            name: "Para İade Kampanyaları", navigationType: "Router", isAuthRequiredToBeVisible: false, route: this.ROUTES.EARN_MONEY,
            active: false, submenuItems: []
          },*/
        ],
      },
      {
        active: false,
        route: this.ROUTES.FAQ_ROUTE,
        name: "Sıkça Sorulan Sorular",
        submenuItems: [],
        isAuthRequiredToBeVisible: false,
        navigationType: "ByAction",
        actionCallback: this.faqModalAction,
      },
    ];
    let currentPath = this.locationRef?.pathname ?? "N/A";
    return data.map((menu) => {
      menu.active =
        menu.route === currentPath ||
        (menu?.submenuItems?.filter((a) => a.route === currentPath).length ??
          0) > 0;
      return menu;
    }) as MenuItem[];
  }

  get layoutPage(): LayoutPage {
    return {
      ...this._layoutPage,
      renderPageStaticBgImages:
        this._layoutPage.renderPageStaticBgImages && !this.mobileMenuOpen,
    };
  }

  get mobileMenuItems(): MenuItem[] {
    let data: MenuItem[] = this.getMenuItems();
    return this.filterAuthRoutes(data);
  }

  get authenticatedMenuItems() {
    return this.getMenuItems();
  }

  get authenticatedMobileMenuItems(): MenuItem[] {
    let data: MenuItem[] = this.getMenuItems();
    return data;
  }

  get menuItems(): MenuItem[] {
    let data: MenuItem[] = this.getMenuItems();
    return this.filterAuthRoutes(data);
  }

  private filterAuthRoutes(data: MenuItem[]): MenuItem[] {
    return data.filter((a) => a.isAuthRequiredToBeVisible === false);
  }

  setRoutes(routes: APP_ROUTES) {
    this.ROUTES = routes;
  }

  setMobileMenuState(state: boolean) {
    this.mobileMenuOpen = state;
  }
  init(location: Location, navigateFunction: NavigateFunction) {
    this.locationRef = location;
    this.navigate = navigateFunction;
    this.updateRenderPageStatus(location);
  }
}

export default ThemeStore;
