import FormInputModel from "../FormInputModel";
import IFormInputModel from "../IFormInputModel";

class LoginFormModel extends FormInputModel implements IFormInputModel{
  /**
   *
   */
  constructor() {
    super({
      defaultFormInputValues:{},
      fieldKeys:[
        "email",
        "password"
      ],
      formLabels:{
        data:{},
        requiredKeys:{
          "email":"Email adı boş bırakılamaz",
          "password": "Şifre en az 8 karakter olmalıdır."
        },
        validation:{}
      }
    });
    this.initForm();
    
  }
}

export default LoginFormModel;