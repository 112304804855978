import dayjs from "dayjs";
import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  set,
  toJS,
} from "mobx";
import { createViewModel, IViewModel, ViewModel } from "mobx-utils";
import {
  debounce,
  filter,
  forkJoin,
  interval,
  map,
  of,
  Subject,
  tap,
  throttle,
} from "rxjs";
import { ProfileView } from "../../model/Model";
import ProfilEditFormModel from "../../model/view/form/user/ProfilEditFormModel";
import { UserLevelView } from "../../model/view/user/UserLevelView";
import UserProfileRestService from "../../services/implementation/rest/app/UserProfileRestService";
import IUserProfileService, {
  Friend,
  GetFriendListResponseWebDto,
  GetHistoryListResponseWebDto,
  UserProfileWebDto,
  UserScoreWebDto,
  UserHistory
} from "../../services/interface/app/IUserProfileService";
import { RootContextWrapper } from "../RootContext";
import IdentityStore from "../user/IdentityStore";
import { PageFilter } from "../../services/interface/IServiceBase";

export default class UserStore {
  userScore: number = 0;
  _userService: IUserProfileService;
  identityStore: IdentityStore;

  isProfileUpdating: boolean = false;
  friendList:Array<Friend>=[];
  newHistoryItems:Array<UserHistory>=[];
  historyList:Array<UserHistory>=[];
  historyItem : GetHistoryListResponseWebDto = { lastID : "", histories : []};
  historyPage=1;

  private _loadScoreStreamSubject: Subject<any> = new Subject();
  userLevelState: UserLevelView | undefined = undefined;

  private _userProfileView: ProfileView;
  profileEditForm: ProfilEditFormModel = new ProfilEditFormModel();
  private _userEditFormSnapshot: string = "{}";

  private updateIntercomInfo:()=>void=()=>{};
  constructor(rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.identityStore = rootContext.identityStore;
    this._userService = new UserProfileRestService(this.identityStore);
    this._userProfileView = {
      address: "",
      birthDate: new Date(0),
      city: "",
      district: "",
      firstName: "",
      lastName: "",
      neighborhood: "",
      genderId: 3,
    };
    this._loadScoreStreamSubject
      .pipe(
        throttle(() => interval(2500)),
        tap(() => {
          if (this.identityStore.isUserAuthenticated) {
            let scoreRequest = this._userService.getUserScore();
            let levelRequest = this._userService.getUserLevel();
            forkJoin([scoreRequest, levelRequest]).subscribe(([s, l]) =>
              runInAction(() => {
                let response = s.data as UserScoreWebDto;
                this.userScore = response.score ?? 200;
                //this.userScore = 8000;
                this.userLevelState = l.data as UserLevelView;
              })
            );
          }
        })
      )
      .subscribe();
      this.updateIntercomInfo = ()=>{
        rootContext.marketingAutomation.runIntercomMessenger();
      }
  }

  private mapWebtoProfileView(res: UserProfileWebDto): ProfileView {
    return {
      address: res.address,
      birthDate: dayjs(res.birthDate).toDate(),
      city: res.city,
      district: res.district,
      firstName: res.firstName,
      genderId: res.gender,
      lastName: res.lastName,
      neighborhood: "",
    };
  }

  get userProfile(): ProfileView {
    return this._userProfileView;
  }

  get getBirthFormatted(): string {
    return dayjs(this._userProfileView.birthDate).format("DD.MM.YYYY");
  }

  get isFormChangeDetected(): boolean {
    return (
      this._userEditFormSnapshot !=
      JSON.stringify(this.profileEditForm.formData)
    );
  }

  updateProfile(profile: Partial<ProfileView>) {
    this._userProfileView = { ...this._userProfileView, ...profile };
    let { address, birthDate, city, district, firstName, genderId, lastName } =
      this._userProfileView;
    this.profileEditForm.updateField("address", address);
    this.profileEditForm.updateField("birthdate", new Date(birthDate));
    this.profileEditForm.updateField("city", city);
    this.profileEditForm.updateField("district", district);
    this.profileEditForm.updateField("firstName", firstName);
    this.profileEditForm.updateField("gender", { id: genderId });
    this.profileEditForm.updateField("firstName", firstName);
    this.profileEditForm.updateField("lastName", lastName);
  }

  snapshotEditForm() {
    this._userEditFormSnapshot = JSON.stringify(this.profileEditForm.formData);
  }

  submitSaveEditProfileForm(onCompleted: () => void) {
    if (this.profileEditForm.validation.isValid) {
      let data = this.profileEditForm.formData;
      let webRequestDto: UserProfileWebDto = {
        address: data["address"],
        birthDate: dayjs(data["birthdate"]).toISOString(),
        city: data["city"],
        district: data["district"],
        firstName: data["firstName"],
        gender: data["gender"].id,
        neighborhood: " ",
        lastName: data["lastName"],
      };
      this._userService.updateProfileInfo(webRequestDto).subscribe(() => {
        this.updateProfile(this.mapWebtoProfileView(webRequestDto));
        onCompleted();
      });
    }
  }

  getFriendList(){
    this._userService.getFriends()
    .pipe(
      map(t => t.data as Friend[])
    ).subscribe(t => runInAction(() => {
      this.friendList = t;
      })
    );
    }

    getHistoryList(pageFilter:PageFilter){
      this._userService.getHistories(pageFilter)
      .pipe(
        map(t => t.data as GetHistoryListResponseWebDto)
      ).subscribe(t => runInAction(() => {
        if(pageFilter.page == 1 && this.historyList.length != 0)
        return;
        this.historyList.push(...t.histories);
        this.newHistoryItems = t.histories;
        })
      );
      }

      getHistoryListByLastId(pageFilter:PageFilter, lastItemId:string | null){
        this._userService.getHistoriesByLastId(pageFilter, lastItemId)
        .pipe(
          map(t => t.data as GetHistoryListResponseWebDto)
        ).subscribe(t => runInAction(() => {
          if(pageFilter.page == 1 && this.historyList.length != 0)
          return;
          this.historyItem = t;
          this.historyList.push(...t.histories);
          this.newHistoryItems = t.histories;
          })
        );
        }

  loadProfileData = action(() => {
    this._userService
      .getProfileInfo()
      .pipe(
        filter((a) => !a.hasErrors()),
        map((a) => a.data as UserProfileWebDto)
      )
      .subscribe((res) =>
        runInAction(() => {
          this.updateProfile(this.mapWebtoProfileView(res));
          this.getFriendList();
          this.getHistoryListByLastId({page:1, take:10}, null);
          this.updateIntercomInfo();
        })
      );
  });

   historyNextPage = (lastID:string)=>{
    runInAction(() =>{
      //this.historyPage = this.historyPage + 1;
      this.getHistoryListByLastId({page:0, take:10}, lastID)
    })
  }

  loadStore() {
    this._loadScoreStreamSubject.subscribe();
    this.loadUserScore();
    this.loadProfileData();
  }

  loadUserScore() {
    this._loadScoreStreamSubject.next({});
  }
}
