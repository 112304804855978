import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IUserProfileService, { UserLevelWebDto, RemaininTaskWebDto, UserScoreWebDto, UserProfileWebDto, UserReferralCodeWebDto, CompleteInvitationWebDto, CompleteInvitationRequestWebDto, GetFriendListResponseWebDto, GetHistoryListResponseWebDto } from "../../../interface/app/IUserProfileService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class UserProfileRestService extends ServiceBase implements IUserProfileService{
  /**
   *
   */
  constructor(identityStore:IdentityStore) {
    super(identityStore);
  }
  getProfileInfo(): Observable<ServiceResponse<UserProfileWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<UserProfileWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/GetInfo`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  updateProfileInfo(request: UserProfileWebDto): Observable<ServiceResponse<void>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<void>>({
      url: `${Env.services.api.host}/API/User/v7/UpdateInfo`,
      method: "POST",
      headers,
      body: request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getUserLevel(): Observable<ServiceResponse<UserLevelWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<UserLevelWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/GetLevel`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getRemaningTasks(): Observable<ServiceResponse<RemaininTaskWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<RemaininTaskWebDto[]>>({
      url: `${Env.services.api.host}/API/Information/v7/GetRemainTask`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getUserScore(): Observable<ServiceResponse<UserScoreWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<UserScoreWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/Point`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getUserReferralCode(): Observable<ServiceResponse<UserReferralCodeWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<UserReferralCodeWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/GetInviteURL`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  completeInvitation(request:CompleteInvitationRequestWebDto): Observable<ServiceResponse<CompleteInvitationWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<CompleteInvitationWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/CompleteInvitation`,
      method: "POST",
      headers,
      body:request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  
  getFriends(): Observable<ServiceResponse<GetFriendListResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<GetFriendListResponseWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/GetFriendList`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getHistories(request: PageFilter): Observable<ServiceResponse<GetHistoryListResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<GetHistoryListResponseWebDto>>({
      url: `${Env.services.api.host}/API/User/v7/GetHistoryListByPage?Page=${request.page}&Take=${request.take}`,
      method: "GET",
      headers,
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getHistoriesByLastId(request: PageFilter, lastItemId:string): Observable<ServiceResponse<GetHistoryListResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<GetHistoryListResponseWebDto>>({
      url:lastItemId != null ? `${Env.services.api.host}/API/User/v7/GetHistoryList?LastID=${lastItemId}&Take=${request.take}` : `${Env.services.api.host}/API/User/v7/GetHistoryList?Take=${request.take}`,
      method: "GET", 
      headers,
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
}