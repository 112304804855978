import {
  IReactionDisposer,
  makeAutoObservable,
  reaction,
  runInAction,
} from "mobx";
import {
  filter,
  forkJoin,
  map,
  mergeMap,
  of,
  Subject,
  Subscription,
  tap,
  throttleTime,
} from "rxjs";
import {
  VideoCategoryToViewModelMapping,
  VideoRestToViewModelMapping,
} from "../../model/mapping/ContentMapping";
import {
  CategoryPageContentView,
  CategoryView,
  ContentView,
  VideoWatchMetaView,
} from "../../model/Model";
import VideoRestService from "../../services/implementation/rest/app/VideoRestService";
import IVideoService, {
  IsVideoWatchedWebDto,
  VideoCategoryWebDto,
  VideoWebDto,
} from "../../services/interface/app/IVideoService";
import { RootContextWrapper } from "../RootContext";
import IdentityStore from "../user/IdentityStore";

const DEFAULTS_VIDEO_WATCHING_META = {
  started: false,
  totalDuration: Number.MAX_VALUE,
  watchedDuration: 0,
  hasBeenNotified: false,
};
export default class ContentVideoStore {
  categoryVideoLandingPage: CategoryView = {
    contentType: "VIDEO",
    desc: "Görevleri tamamlayın, puanları toplayın. Topladığınız puanlarla herkese meydan okuyun, ödülleri kapın!",
    header: "Görevler",
    id: "LANDINGPAGE1",
  };

  activeModalVideoId: string = "";
  showVideoPlayer: boolean = false;

  activeWatchingVideoMeta: VideoWatchMetaView = DEFAULTS_VIDEO_WATCHING_META;

  videoService: IVideoService;
  private videoContentContentsPage: CategoryPageContentView[] = [];
  videoContentLandingPage: ContentView[] = [];

  identityStore: IdentityStore;

  videoWatchingPageSource: "LANDINGPAGE" | "CONTENTPAGE" | "NA" = "NA";
  notifyUserScoreLoad: () => void;

  private _videoWatchNotifyCallback: Subject<any> = new Subject();
  constructor(rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.videoService = new VideoRestService(rootContext.identityStore);
    this.identityStore = rootContext.identityStore;
    this.notifyUserScoreLoad = () => {
      rootContext.userStore.loadUserScore();
    };
  }

  loadLandingVideos() {
    this.videoService
      .getHomePageVideos()
      .pipe(
        map((r) => r.data as VideoWebDto[]),
        map((r) => r.map((a) => VideoRestToViewModelMapping(a, "LANDINGPAGE1")))
      )
      .subscribe((r) =>
        runInAction(() => {
          this.videoContentLandingPage = r;
        })
      );
  }

  loadContentPageCategoryAndVideos() {
    this.videoService
      .getVideoCategories()
      .pipe(
        mergeMap((levelMeta) => {
          let result = levelMeta.data
            .map((level) => level as VideoCategoryWebDto[])
            .flatMap((level) => level)
            .map((level) =>
              this.videoService.getCategoryVideos(level.id).pipe(
                map((levelMeta) => levelMeta.data as VideoWebDto[]),
                map((levelDetail) => {
                  return { levelDetail, levelMeta: level };
                })
              )
            );
          return forkJoin(result);
        }),
        tap((detailMerge) =>
          runInAction(() => {
            let mapping = detailMerge.map((detailRest) => {
              let pageView: CategoryPageContentView = {
                category: [
                  VideoCategoryToViewModelMapping(detailRest.levelMeta),
                ],
                contents: detailRest.levelDetail.map((a) =>
                  VideoRestToViewModelMapping(a, detailRest.levelMeta.id)
                ),
              };
              return pageView;
            });

            this.videoContentContentsPage = [
              {
                category: mapping.flatMap((a) => a.category),
                contents: mapping.flatMap((a) => a.contents),
              },
            ];
          })
        )
      )
      .subscribe();
  }

  setActiveModalLandingPageVideo(contentId: string) {
    runInAction(() => {
      this.activeModalVideoId = contentId;
      this.showVideoPlayer = true;
      this.videoWatchingPageSource = "LANDINGPAGE";
    });
  }
  setActiveModalContentPageVideo(contentId: string) {
    runInAction(() => {
      this.activeModalVideoId = contentId;
      this.showVideoPlayer = true;
      this.videoWatchingPageSource = "CONTENTPAGE";
    });
  }

  updateVideoWatchMetaView(meta: Partial<VideoWatchMetaView>) {
    runInAction(() => {
      this.activeWatchingVideoMeta = {
        ...this.activeWatchingVideoMeta,
        ...meta,
      };
    });
  }

  closActiveVideoModal() {
    runInAction(() => {
      this.showVideoPlayer = false;
      this.notifyUserScoreLoad();
      this.updateVideoWatchMetaView(DEFAULTS_VIDEO_WATCHING_META);
    });
  }

  get activeVideo(): {
    content: ContentView | undefined;
    contentExists: boolean;
  } {
    switch (this.videoWatchingPageSource) {
      case "CONTENTPAGE":
        if (
          this.videoContentContentsPage.flatMap((a) => a.contents).length > 0
        ) {
          let content = this.videoContentContentsPage
            .flatMap((a) => a.contents)
            .filter((a) => a.contentId === this.activeModalVideoId)[0];
          return {
            contentExists: content != undefined,
            content,
          };
        }
        break;
      case "LANDINGPAGE":
        let content = this.videoContentLandingPage.filter(
          (a) => a.contentId === this.activeModalVideoId
        )[0];
        return {
          contentExists: content != undefined,
          content,
        };
    }
    return {
      contentExists: false,
      content: undefined,
    };
  }

  get contentPageVideoItemsWithCategory(): CategoryPageContentView | undefined {
    return (
      (this.videoContentContentsPage.length > 0 &&
        this.videoContentContentsPage[0]) ||
      undefined
    );
  }

  get canVideoPlayed(): boolean {
    return this.activeVideo.contentExists;
  }

  private _videoWatchSubscription: Subscription = of().subscribe();
  private _reactionDispose: IReactionDisposer = reaction(
    () => this.activeWatchingVideoMeta.watchedDuration,
    () => {}
  );

  loadStore() {
    this._reactionDispose();
    this._reactionDispose = reaction(
      () => this.activeWatchingVideoMeta.watchedDuration,
      () => {
        let { watchedDuration, totalDuration, hasBeenNotified } =
          this.activeWatchingVideoMeta;
        let completePercent = (watchedDuration / totalDuration) * 100;
        let userAuth = this.identityStore.isUserAuthenticated;
        if (
          watchedDuration > 3 &&
          completePercent > 85 &&
          !hasBeenNotified &&
          userAuth
        ) {
          this._videoWatchNotifyCallback.next({
            watchedDuration,
            completePercent,
          });
        }
      }
    );
    this._videoWatchSubscription.unsubscribe();
    this._videoWatchSubscription = this._videoWatchNotifyCallback
      .pipe(throttleTime(10000))
      .subscribe(() => {
        this.notifyWatchedVideoToRest(
          this.activeWatchingVideoMeta.watchedDuration
        );
      });
  }

  private notifyWatchedVideoToRest(duration: number) {
    this.videoService
      .postWatchVideo({
        videoID: this.activeModalVideoId,
        duration: Math.ceil(duration),
      })
      .pipe(
        mergeMap((r)=>{
          if(!r.hasErrors()){
            return this.videoService.isVideoWatched(this.activeModalVideoId).pipe(
              map(a=> a.data as IsVideoWatchedWebDto),
              filter(a=> a.isWatched)
            )
          }
          return of();
        })
      )
      .subscribe(() => {
        this.updateVideoWatchMetaView({ hasBeenNotified: true });
        this.updateVideoWatchedLocalState(this.activeModalVideoId, true);
      });
  }

  resetVideoMetaState() {
    this.updateVideoWatchMetaView(DEFAULTS_VIDEO_WATCHING_META);
  }

  private updateVideoWatchedLocalState(contentId: string, state: boolean) {
    let updateVideoState = (v: ContentView) => {
      runInAction(() => {
        v.completed = state;
      });
    };
    this.videoContentContentsPage
      .flatMap((a) => a.contents)
      .forEach((v) => {
        if (v.contentId === this.activeModalVideoId) {
          updateVideoState(v);
        }
      });
    this.videoContentLandingPage.forEach((v) => {
      if (v.contentId === this.activeModalVideoId) {
        updateVideoState(v);
      }
    });
  }
}
