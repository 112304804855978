import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  toJS,
} from "mobx";
import { runMain } from "module";
import { delay, filter, interval, map, mergeMap, of, tap } from "rxjs";
import { ROUTES } from "../../Routes";
import RewardRestService from "../../services/implementation/rest/app/RewardRestService";
import IRewardService, {
  CampaignWebDto,
  ChoicePostWebRequest,
  ChoicePostWebResponse,
  CodePostResponseWebDto,
  CouponListItemWebDto,
} from "../../services/interface/app/IRewardService";
import { PageFilter } from "../../services/interface/IServiceBase";
import { RootContextWrapper } from "../RootContext";

type ReservationRequest = {
  errorMessage: string;
  transcationId: string;
  transaction: "DATA-FETCHING" | "CODE-REQUESTING" | "CHOICE-PROCESSING" | "NA";
  phase: "INIT" | "CODE-REQUESTED" | "CHOICE_SUBMITTED";
  isCompletedSuccessFully: boolean;
  reservationResponse: string;
};

const INITAL_REQUEST_STATE: ReservationRequest = {
  errorMessage: "",
  isCompletedSuccessFully: true,
  transaction: "NA",
  phase: "INIT",
  reservationResponse: "",
  transcationId: "",
};

const INITIAL_COUPON_STATE: CodePostResponseWebDto = {
  isSuccess: false,
  message: "",
  processID: "",
  detailWhereIsValid: "",
  detailValue: "",
  choices: [],
};

const INITIAL_CHOICE_STATE: ChoicePostWebResponse = {
  isSuccess: false,
  isNextStepRequired: false,
  processID: "",
  message: "",
  storeName: "",
  productName: "",
  points: 0,
  choiceType: "",
  detailWhereIsValid: "",
  code: "",
  detailValue: "",
};

export type CampaignViewModel = {
  id: string;
  title: string;
  coin: number;
  buyLink: string;
  imageUrl: string;
  redirectButtonTitle: string;
  isRedirectURLAvailable: boolean;
};

export type CouponViewModel = {
  code: string;
  detailWhereIsValid: string;
  detailValue: string;
};

export default class CouponStore {
  private _transaction: ReservationRequest = INITAL_REQUEST_STATE;
  private _campainModalState: {
    isOpen: boolean;
    selectedCampaign?: string;
    isDropdownActive: boolean;
  } = {
    isOpen: false,
    isDropdownActive: false,
  };
  private _couponListModalState: {
    isOpen: boolean;
    page: number;
    itemCount: number;
  } = {
    isOpen: false,
    page: 1,
    itemCount: 5,
  };
  private _enterCouponModalState: {
    isOpen: boolean;
    infoPopupOpen: boolean;
    fromMainPage: boolean;
  } = {
    isOpen: false,
    infoPopupOpen: false,
    fromMainPage: true,
  };

  private _postedCouponModelResponse: CodePostResponseWebDto =
    INITIAL_COUPON_STATE;

  private _postedChoiceModelResponse: ChoicePostWebResponse =
    INITIAL_CHOICE_STATE;

  private _campaigns: CampaignViewModel[] = [];
  private _coupons: CouponViewModel[] = [];
  private _rewardService: IRewardService;
  private _visibleCoupons: CouponViewModel[] = [];
  private _identityStore: import("../user/IdentityStore").default;
  constructor(context: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._rewardService = new RewardRestService({
      auth: context.identityStore,
    });
    this._identityStore = context.identityStore;
  }

  loadStore = () => {};

  getAllCampaignList = () => {
    reaction(
      () => this._campainModalState.isOpen,
      (c, p) => {
        if (c && !p) {
          this.fetchCampaignList();
        }
      }
    );
  };

  getAllCouponList = () => {
    reaction(
      () => this._campainModalState.isOpen,
      (c, p) => {
        if (c && !p) {
          this.fetchCouponList();
        }
      }
    );
  };

  get couponListModalState() {
    return this._couponListModalState;
  }

  get enterCouponModalState() {
    return this._enterCouponModalState;
  }

  get coupons(): CouponViewModel[] {
    return this._coupons;
  }

  resetCouponPage() {
    runInAction(() => {
      this._couponListModalState.page = 1;
      this._visibleCoupons.length = 0;
    });
  }

  get campaigns(): CampaignViewModel[] {
    return this._campaigns;
  }
  get campaignState() {
    return this._campainModalState;
  }

  get isCampaignSelected(): boolean {
    let selectedId = this.selectedCampaign?.id ?? "";
    return selectedId.length > 0;
  }

  get selectedCampaign(): CampaignViewModel | undefined {
    return this._campaigns.filter(
      (a) => a.id === this._campainModalState.selectedCampaign
    )[0];
  }
  get transactionStatus() {
    return this._transaction;
  }

  get isTransactionActive() {
    return this._transaction.transaction !== "NA";
  }

  fetchCouponList(page: number = 1, take: number = 5) {
    this._transaction.transaction = "DATA-FETCHING";
    this._rewardService
      .getCollectedCoupons(new PageFilter({ page, take }))
      .pipe(
        tap((r) => {
          if (r.hasErrors()) {
            this._transaction.isCompletedSuccessFully = false;
            this._transaction.transaction = "NA";
          }
        }),
        filter((r) => !r.hasErrors()),
        map((r) => r.data as CouponListItemWebDto[]),
        tap((r) => {
          let couponMappings = r.map((s) => {
            let model: CouponViewModel = {
              code: s.code,
              detailWhereIsValid: s.detailWhereIsValid,
              detailValue: s.detailValue,
            };
            return model;
          });
          runInAction(() => {
            this._coupons = [...couponMappings];
            this._visibleCoupons.push(...couponMappings);
            this._transaction = { ...INITAL_REQUEST_STATE };
          });
        })
      )
      .subscribe();
  }

  fetchCampaignList() {
    this._transaction.transaction = "DATA-FETCHING";
    this._rewardService
      .getAllCampaigns()
      .pipe(
        tap((r) => {
          if (r.hasErrors()) {
            this._transaction.isCompletedSuccessFully = false;
            this._transaction.transaction = "NA";
          }
        }),
        filter((r) => !r.hasErrors()),
        map((r) => r.data as CampaignWebDto[]),
        tap((r) => {
          let campaignMappings = r.map((s) => {
            let model: CampaignViewModel = {
              buyLink: s.redirectURL ?? "",
              coin: s.isPointAvailable ? s.point ?? 200 : 200,
              id: s.id,
              imageUrl: s.photo ?? "/public/assets/survey-question-bg.png",
              title: s.title,
              redirectButtonTitle: s.redirectButtonTitle,
              isRedirectURLAvailable: s.isRedirectURLAvailable,
            };
            return model;
          });
          runInAction(() => {
            this._campaigns = [...campaignMappings];
            this._transaction = { ...INITAL_REQUEST_STATE };
          });
        })
      )
      .subscribe();
  }

  choicePost(choiceCode: string, processId: string) {
    runInAction(() => {
      this._transaction.transaction = "CHOICE-PROCESSING";
      this._transaction.transcationId = processId ?? "";

      let transactionError = () =>
        runInAction(() => {
          this._transaction.transaction = "NA";
          this._transaction.isCompletedSuccessFully = false;
        });

      this._rewardService
        .choicePost({
          rewardChoiceID: choiceCode,
          processID: processId ?? "NA",
        })
        .pipe(
          tap((r) =>
            runInAction(() => {
              this._transaction.phase = "CHOICE_SUBMITTED";
              let choiceResponse = r.data as ChoicePostWebResponse;
              this._postedChoiceModelResponse = choiceResponse;

              if (r.hasErrors() || !choiceResponse.isSuccess) {
                transactionError();
                this._transaction.errorMessage = choiceResponse.message;
                return of();
              }
              this._transaction.isCompletedSuccessFully = true;
              this._transaction.transaction = "NA";
              /*
              return this._rewardService.choicePost({
                processID: codeResponse.processID,
                rewardChoiceID: codeResponse.choices[0].rewardChoiceID,
              });
              */
            })
          )
        )
        .subscribe();
    });
  }

  codeRequest(couponCode: string) {
    runInAction(() => {
      if (!this.selectedCampaign?.id) return;
      if (!couponCode) return;
      this._transaction.transaction = "CODE-REQUESTING";
      this._transaction.transcationId = this.selectedCampaign?.id ?? "";
      // Debug Helper
      // new Promise((r) => setTimeout(() => r({}), 1500))
      //   .then(() => {
      //     //success
      //     runInAction(() => {
      //       this._transaction.phase = "CODE-REQUESTED";
      //       // throw "Invalid Code"

      //       //Valid Code Choice Request
      //       this._transaction.transaction="CHOICE-PROCESSING";
      //       new Promise((cr)=>setTimeout(() => cr({}), 500)).then(()=>runInAction(()=>{
      //         this._transaction.transaction = "NA";
      //         this._transaction.phase = "CHOICE_SUBMITTED";
      //         this._transaction.isCompletedSuccessFully=true;
      //         //throw "Invalid Choice";
      //       })).catch(()=>runInAction(()=>{
      //         this._transaction.transaction = "NA";
      //         this._transaction.isCompletedSuccessFully=false;
      //       }))
      //     });
      //   })
      //   .catch(() => {
      //     runInAction(() => {
      //       this._transaction.transaction = "NA";
      //       this._transaction.isCompletedSuccessFully = false;
      //     });
      //   });
      let transactionError = () =>
        runInAction(() => {
          this._transaction.transaction = "NA";
          this._transaction.isCompletedSuccessFully = false;
        });

      this._rewardService
        .codePost({
          code: couponCode,
          rewardCampaignID: this.selectedCampaign?.id ?? "NA",
        })
        .pipe(
          tap((r) =>
            runInAction(() => {
              this._transaction.phase = "CODE-REQUESTED";
              let codeResponse = r.data as CodePostResponseWebDto;
              this._postedCouponModelResponse = codeResponse;

              if (r.hasErrors() || !codeResponse.isSuccess) {
                transactionError();
                this._transaction.errorMessage = codeResponse.message;
                return of();
              }
              this._transaction.isCompletedSuccessFully = true;
              this._transaction.transaction = "NA";
              /*
              return this._rewardService.choicePost({
                processID: codeResponse.processID,
                rewardChoiceID: codeResponse.choices[0].rewardChoiceID,
              });
              */
            })
          )
          /*
          tap((choiceResult) =>
            runInAction(() => {
              this._transaction.phase = "CHOICE_SUBMITTED";
              let result = choiceResult.data as ChoicePostWebResponse;
              if (choiceResult.hasErrors() || !result.isSuccess) {
                transactionError();
                this._transaction.errorMessage = result.message;
                return of();
              }
              this._transaction.isCompletedSuccessFully = true;
              this._transaction.transaction = "NA";
            })
          )
          */
        )
        .subscribe();
    });
  }

  setDropdownStatus(status: boolean) {
    this._campainModalState.isDropdownActive = status;
  }
  selectCampaign(campaignId: string) {
    this.campaignState.selectedCampaign = campaignId;
    if (!this._identityStore.isUserAuthenticated) {
      window.location.pathname = ROUTES.LOGIN_ROUTE;
      this.closeCampainModal();
    }
  }
  openCampainModal() {
    this._campainModalState.isOpen = true;
  }

  openCouponListModal() {
    this._couponListModalState.isOpen = true;
  }

  openEnterCouponModal(fromMain: boolean) {
    this._enterCouponModalState.isOpen = true;
    this._enterCouponModalState.fromMainPage = fromMain;
  }

  resetTransactionState() {
    runInAction(() => {
      this._transaction = observable(INITAL_REQUEST_STATE);
    });
  }

  resetInputs() {
    runInAction(() => {
      this._postedCouponModelResponse = observable(INITIAL_COUPON_STATE);
      this._postedChoiceModelResponse = observable(INITIAL_CHOICE_STATE);
    });
  }

  closeCampainModal() {
    this._campainModalState.isOpen = false;
    this.resetTransactionState();
  }
  closeCouponListModal() {
    this._couponListModalState.isOpen = false;
  }

  closeEnterCouponModal() {
    this._enterCouponModalState.isOpen = false;
  }

  openInfoPopupModal() {
    this._enterCouponModalState.infoPopupOpen = true;
  }

  closeInfoPopupModal() {
    this._enterCouponModalState.infoPopupOpen = false;
  }

  get selectedCouponResponseHasChoices() {
    return (
      this._postedCouponModelResponse.choices &&
      this._postedCouponModelResponse.choices.length > 0
    );
  }

  get selectedCouponResponse() {
    return this._postedCouponModelResponse;
  }

  get selectedCouponResponseChoices() {
    return this._postedCouponModelResponse.choices;
  }

  get selectedChoiceResponse() {
    return this._postedChoiceModelResponse;
  }

  couponNextPage() {
    runInAction(() => {
      this._couponListModalState.page++;
      this.fetchCouponList(
        this._couponListModalState.page,
        this._couponListModalState.itemCount
      );
    });
  }

  nextPageButtonVisible() {
    return this._coupons.length > this._couponListModalState.itemCount;
  }

  get visibleCoupons(): CouponListItemWebDto[] {
    return this._visibleCoupons;
  }
}
