import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { SignUpModel } from "../../model/Model";
import IFormInputModel from "../../model/view/form/IFormInputModel";
import {
  AuthService,
  UserRegisterDto,
} from "../../services/implementation/rest/AuthService";
import dayjs from "dayjs";
import {
  ClientTokenStorageModel,
  UserRopcToken,
} from "../../services/interface/IAuthService";
import LoginFormModel from "../../model/view/form/user/LoginFormModel";
import { ServiceError } from "../../services/interface/IServiceBase";
import IUserSessionStorageService from "../../services/interface/IUserSessionStorageService";
import UserSessionLocalStorageService from "../../services/implementation/storage/UserSessionLocalStorageService";
import { NavigateFunction } from "react-router-dom";
import { of, mergeMap, tap, delay, Subject, scan, map } from "rxjs";
import { now } from "mobx-utils";
import { RootContextWrapper } from "../RootContext";
import { pgEventPush } from "../../../env";
import UserProfileRestService from "../../services/implementation/rest/app/UserProfileRestService";

export enum PROCESS_STATE {
  NA = 0,
  DONE = 1,
  AWAITING = 2,
}
type LoginAttemptAcc = {
  attempts?: {
    [email: string]: {
      invalidAttemptCount: number;
      lastTry: Date;
      lockedUntil: Date;
      isLocked: boolean;
    };
  };
  email: string;
  when: Date;
};
const ATTEMT_STORE_KEY = "attempts";
class IdentityStore {
  private _signUpForm: IFormInputModel;
  private _loginForm: IFormInputModel;
  private _formSubmissionState: PROCESS_STATE = PROCESS_STATE.NA;
  private _isLoginActive: PROCESS_STATE = PROCESS_STATE.NA;
  private _userToken: UserRopcToken = new UserRopcToken();
  loginServiceErrors: ServiceError[] = [];
  signUpServiceErrors: ServiceError[] = [];
  private _clientCredToken?: ClientTokenStorageModel;
  private _reCaptchaValidationTokenVersion: number = 0;
  private _captchaChangeSubject = new Subject<number>();
  private _authService: AuthService;
  private _userService: UserProfileRestService;
  private _userSessionStorage: IUserSessionStorageService;
  private _userCaptchaToken: string = "";
  public registerSuccessful = false;
  private _invalidLoginAttemptLogStream: Subject<LoginAttemptAcc> =
    new Subject();
  private _notifyUserLoad: () => void = () => {};
  constructor(rootContext: RootContextWrapper) {
    this._signUpForm = new SignUpModel();
    this._loginForm = new LoginFormModel();
    this._authService = new AuthService(() => this);
    this._userService = new UserProfileRestService(this);
    this._userSessionStorage = new UserSessionLocalStorageService();
    makeAutoObservable(this, {}, { autoBind: true });
    this.setupUserToken();
    this._notifyUserLoad = () => {
      rootContext.userStore.loadUserScore();
    };
  }

  private setupUserToken() {
    this._userSessionStorage.loadUserToken().subscribe((token) => {
      runInAction(() => {
        this._userToken = new UserRopcToken(token);
      });
    });
    reaction(
      () => this.userToken,
      (token) => {
        if (token) {
          this._userSessionStorage.saveUserToken(token);
        }
      }
    );

    this._captchaChangeSubject.subscribe((c) =>
      runInAction(() => {
        this._reCaptchaValidationTokenVersion = c;
      })
    );

    // Refresh token disabled.Comment out to enable
    // reaction(
    //   () => !this.isUserAuthenticated && this.isRefreshTokenValid,
    //   () => {
    //     if (!this.isUserAuthenticated && this.isRefreshTokenValid) {
    //       this.renewUserSessionWithRefreshToken();
    //     }
    //   }
    // );

    // this.renewUserSessionWithRefreshToken();
  }
  //Refresh token is not being used. Long duration one time session applied.
  // private renewUserSessionWithRefreshToken() {
  //   return this._authService
  //     .refreshUserToken()
  //     .pipe(
  //       mergeMap((result) => {
  //         if (result.errors.length > 0) {
  //           this._userSessionStorage.cleanSession().subscribe(() => {
  //             runInAction(() => {
  //               this._userToken = new UserRopcToken();
  //             });
  //           });
  //           return of();
  //         } else {
  //           return of(result);
  //         }
  //       })
  //     )
  //     .subscribe((serviceResult) =>
  //       action(() => {
  //         if (serviceResult.data && serviceResult.errors.length === 0) {
  //           this._userToken = new UserRopcToken(
  //             serviceResult.data as UserRopcToken
  //           );
  //           this._notifyUserLoad();
  //         }
  //       })()
  //     );
  // }

  loadStore() {
    const localStoreAttempts = JSON.parse(
      localStorage.getItem(ATTEMT_STORE_KEY) ?? "{}"
    ) as {
      [email: string]: {
        invalidAttemptCount: number;
        lastTry: Date;
      };
    };
    this._invalidLoginAttemptLogStream
      .pipe(
        scan(
          (acc, attempt) => {
            if (acc.attempts) {
              if (acc.attempts[attempt.email] === undefined) {
                acc.attempts[attempt.email] = {
                  invalidAttemptCount: 0,
                  lastTry: new Date(1970),
                  lockedUntil: new Date(1970),
                  isLocked: false,
                };
              }
              acc.attempts[attempt.email].invalidAttemptCount += 1;
              acc.attempts[attempt.email].lastTry = attempt.when;
              if (
                acc.attempts[attempt.email].invalidAttemptCount > 5 &&
                !acc.attempts[attempt.email].isLocked
              ) {
                acc.attempts[attempt.email].lockedUntil = dayjs(new Date())
                  .add(2, "minute")
                  .toDate();
              }
              acc.attempts[attempt.email].isLocked =
                dayjs(acc.attempts[acc.email]?.lockedUntil).diff(
                  new Date(),
                  "seconds"
                ) > 0;
              acc.email = attempt.email;
              acc.when = new Date();
            }

            return acc;
          },
          {
            attempts: localStoreAttempts,
            email: "",
            when: new Date(),
          } as LoginAttemptAcc
        ),
        tap((state) => {
          if (state.attempts && state.attempts[state.email]?.isLocked) {
            this.loginServiceErrors = [
              {
                Code: "INVLGN",
                text: `Hesabınız kitlendi. ${dayjs(new Date()).diff(
                  state.attempts[state.email].lockedUntil,
                  "seconds"
                )} saniye süre içinde tekrar deneyiniz.`,
              },
            ];
          } else {
            this.loginServiceErrors = [
              { Code: "INVLGN", text: "Hatalı kullanıcı yada parola" },
            ];
          }
        }),
        tap((storeState) => {
          localStorage.setItem(
            ATTEMT_STORE_KEY,
            JSON.stringify(storeState.attempts)
          );
        })
      )
      .subscribe();
  }

  sentSignUpForm(navigate?: NavigateFunction) {
    this._formSubmissionState = PROCESS_STATE.AWAITING;

    this.incrementRecaptchaValidationTokenVersion();
    let userRegisterModel: UserRegisterDto = {
      password: this.signupModel.formField("password"),
      user: {
        birthDate: dayjs(this.signupModel.formField("bdate")),
        email: this.signupModel.formField("email"),
        firstName: this.signupModel.formField("firstName"),
        lastName: this.signupModel.formField("lastName"),
        gender: 1,
        isCheckClarificationText: this.signupModel.formField("kvkk") ?? false,
        isCheckTermsOfUse: this.signupModel.formField("agreement"),
        agreementLast: this.signupModel.formField("agreement_last"),
      },
    };
    const signupSub = this._captchaChangeSubject.subscribe(() => {
      signupSub.unsubscribe();
      this._authService
        .registerProtectedUser(userRegisterModel)
        .pipe(delay(500))
        .subscribe((response) => {
          if (response.errors.length === 0) {
            pgEventPush({
              event: "customEvent",
              GAeventCategory: "event_crm_action",
              GAeventAction: "event_profile_register_complete",
              GAeventLabel: "",
              GAeventValue: 0,
              GAeventNonInteraction: false,
            });
            runInAction(() => {
              this._loginForm.updateField(
                "email",
                this.signupModel.formField("email")
              );
              this._loginForm.updateField(
                "password",
                this.signupModel.formField("password")
              );
            });
            this.loginAsUser(navigate, true, this.signupModel.formField("refCode"));
          } else {
            runInAction(() => {
              this._formSubmissionState = PROCESS_STATE.NA;
              if (
                response.errors &&
                response.errors.filter((a) => a.Code === "e18").length > 0
              ) {
                this.signUpServiceErrors = [
                  {
                    Code: "e18",
                    text: "Bu email adresi daha önceden kullanılmış",
                  },
                ];
              } else {
                this.signUpServiceErrors = [];
              }
            });
          }
        });
    });
  }

  renewClientAccessToken() {
    this._authService.getClientCredentialsToken().subscribe((result) => {
      if (result.data) {
        this.setClientToken(
          new ClientTokenStorageModel(result.data as ClientTokenStorageModel)
        );
      }
    });
  }

  setClientToken(clientToken: ClientTokenStorageModel) {
    runInAction(() => {
      this._clientCredToken = observable(clientToken);
    });
  }

  setRecaptchaValdationToken = action((token: string) => {
    this._userCaptchaToken = token;
    this._captchaChangeSubject.next(this._reCaptchaValidationTokenVersion);
  });

  incrementRecaptchaValidationTokenVersion = action(() => {
    this._reCaptchaValidationTokenVersion += 1;
  });

  logOutAndClearSession() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  }

  loginAsUser(navigate?: NavigateFunction, fromRegister:boolean = false, referralCode:string = "") {
    this._isLoginActive = PROCESS_STATE.AWAITING;
    let email = this._loginForm.formField("email");
    this.incrementRecaptchaValidationTokenVersion();
    const sub = this._captchaChangeSubject.subscribe(() =>
      runInAction(() => {
        sub.unsubscribe();
        this._authService
          .protectedLogin(email, this._loginForm.formField("password"))
          .pipe(
            mergeMap((response) => {
              if (
                response.errors &&
                response.errors.filter((a) => a.Code === "INVLGN").length > 0
              ) {
                runInAction(() => {
                  this._invalidLoginAttemptLogStream.next({
                    email: email.toString(),
                    when: new Date(),
                  });
                  this._isLoginActive = PROCESS_STATE.DONE;
                  this._formSubmissionState = PROCESS_STATE.NA;
                });
              } else {
                if (
                  response.errors.filter((a) => a.Code === "MALICIOUS_CLIENT")
                    .length > 0
                ) {
                  this.loginServiceErrors = [
                    {
                      Code: "MALICIOUS_CLIENT",
                      text: "Geçersiz oturum algılandı. Giriş için tekrar deneyiniz",
                    },
                  ];
                } else if (response.data) {
                  return runInAction(() => {
                    this._userToken = new UserRopcToken(
                      response.data as UserRopcToken
                    );

                    this.loginServiceErrors = response.errors.map((a) => {
                      return { text: a.text, Code: a.Code };
                    });
                    if (this.userHasValidSession) {
                      return this._authService
                        .notifyLoginApplicationUser()
                        .pipe(
                          tap(
                            action(() => {
                              this._signUpForm.clearForm();
                              this.loginServiceErrors = [];
                              this._isLoginActive = PROCESS_STATE.DONE;
                              this._formSubmissionState = PROCESS_STATE.NA;
                              this.signUpServiceErrors = [];
                              if(fromRegister){
                                
                               this._userService.completeInvitation({code:referralCode}).pipe(
                                
                               ).subscribe();
                              }
                              
                              if (navigate) {
                                navigate("/", { replace: true });
                                window.scrollTo({ top: 0 });
                              }
                              
                              
                            })
                          )
                        );
                    }
                    return of();
                  });
                }
              }
              return of();
            })
          )
          .subscribe({
            error: action(() => {
              this._isLoginActive = PROCESS_STATE.DONE;
            }),
          });
      })
    );
  }

  get userVerificationCaptchaVersion() {
    return this._reCaptchaValidationTokenVersion;
  }
  get userVerificationCaptcha() {
    return this._userCaptchaToken;
  }
  get userHasValidSession(): boolean {
    return (
      this.isUserAuthenticated === true || this.isRefreshTokenValid === true
    );
  }

  get isUserAuthenticated(): boolean {
   // return true;    //TODO: silinecek
    return (
      this._userToken &&
      dayjs(this._userToken.expiresAt)
        .subtract(10, "seconds")
        .diff(new Date(now(1500))) > 0
    );
  }

  get isRefreshTokenValid(): boolean {
    return (
      this._userToken &&
      dayjs(this._userToken.refreshTokenExpiresAt)
        .subtract(10, "seconds")
        .diff(new Date(now(1500))) > 0
    );
  }

  get isClientTokenValid(): boolean {
    return (
      this._clientCredToken !== undefined &&
      dayjs(this._clientCredToken.expiresAt)
        .subtract(10, "seconds")
        .diff(new Date(now(1500))) > 0
    );
  }

  get isLoginActive(): boolean {
    return this._isLoginActive === PROCESS_STATE.AWAITING;
  }

  get formSubmissionState(): PROCESS_STATE {
    return this._formSubmissionState;
  }

  get signupModel(): IFormInputModel {
    return this._signUpForm;
  }

  get userEmail(): string {
    let parsedToken = this.userToken?.parseToken() as any;
    if (parsedToken) {
      return parsedToken.sub as string;
    }
    return "";
  }

  get userId(): string {
    let parsedToken = this.userToken?.parseToken() as any;
    if (parsedToken) {
      return parsedToken.uid as string;
    }
    return "";
  }

  get userHmac(): string {
    let parsedToken = this.userToken?.parseToken() as any;
    if (parsedToken) {
      return parsedToken.hmac as string;
    }
    return "";
  }

  get loginFormModel(): IFormInputModel {
    return this._loginForm;
  }

  get clientToken(): ClientTokenStorageModel | undefined {
    return this._clientCredToken;
  }

  get userToken(): UserRopcToken | undefined {
    return this._userToken;
  }
}

export default IdentityStore;
