import { makeAutoObservable, runInAction } from "mobx";
import { LeaderView } from "../../model/view/athlet/Athlet";
import {v4 as uuidv4} from 'uuid';
import ILeaderService, {
  LeaderBoardResponseWebDto,
} from "../../services/interface/app/ILeaderService";
import RootContext, { RootContextWrapper } from "../RootContext";
import LeaderRestService from "../../services/implementation/rest/app/LeaderRestService";
import { PageFilter } from "../../services/interface/IServiceBase";
import { filter, forkJoin, last, map, mergeMap, of, tap } from "rxjs";

export default class LeaderStore {
  leaderBoardWeeklyPageContent: LeaderView[] = [];
  leaderBoardMonthlyPageContent: LeaderView[] = [];

  _leaderBoardRestService: ILeaderService;
  constructor(rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
    this._leaderBoardRestService = new LeaderRestService(
      rootContext.identityStore
    );
  }

  loadLeaderBoard() {
    let request1 = this._leaderBoardRestService
      .getMonthlyLeaders(new PageFilter({ page: 1, take: 5 }))
      .pipe(
        filter((a) => !a.hasErrors()),
        map((a) => a.data as LeaderBoardResponseWebDto),
        last(),
        tap((dto) =>
          runInAction(() => {
            this.leaderBoardMonthlyPageContent = dto.users.map((userDto) => {
              let view: LeaderView = {
                categoryId: "M1",
                id: uuidv4(),
                name: userDto.fullName,
                avatarImgUrl: userDto.photoURL,
                score: userDto.point.toString(),
                categoryName: "Kullanıcı",
              };
              return view;
            });
          })
        )
      );
    let request2 = this._leaderBoardRestService
      .getWeeklyLeaders(new PageFilter({ page: 1, take: 5 }))
      .pipe(
        filter((a) => !a.hasErrors()),
        map((a) => a.data as LeaderBoardResponseWebDto),
        last(),
        tap((dto) =>
          runInAction(() => {
            this.leaderBoardWeeklyPageContent = dto.users.map((userDto) => {
              let view: LeaderView = {
                categoryId: "M1",
                id: uuidv4(),
                name: userDto.fullName,
                avatarImgUrl: userDto.photoURL,
                score: userDto.point.toString(),
                categoryName: "Kullanıcı",
              };
              return view;
            });
          })
        )
      );
    forkJoin([request1, request2]).pipe().subscribe();
    
  }

  loadStore() {}
}
