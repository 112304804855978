import { makeAutoObservable, runInAction } from "mobx";
import { map,tap } from "rxjs";
import { QuestionAnswerView } from "../../model/view/question/Question";
import QuestionRestService from "../../services/implementation/rest/app/QuestionRestService";
import IQuestionService, { QuestionAnswerWebDto } from "../../services/interface/app/IQuestionService";
import { PageFilter } from "../../services/interface/IServiceBase";
import { RootContextWrapper } from "../RootContext";

export default class QuestionStore{
  lastAnswerQuestionPageContent:QuestionAnswerView[]=[];
  questionService:IQuestionService;
  notifyScoreUpdate:()=>void;
  constructor(rootContext:RootContextWrapper) {
    makeAutoObservable(this,{},{autoBind:true});
    this.questionService = new QuestionRestService(rootContext.identityStore);
    this.notifyScoreUpdate = ()=>{
      rootContext.userStore.loadUserScore();
      rootContext.activityStore.openRemaningModal();
    }
  }


  askQuestion(categoryId:string,text:string){
    this.questionService.askQuestion({athleteCategoryID:categoryId,title:text}).subscribe(res=>{
      if(!res.hasErrors()){
        this.notifyScoreUpdate();
      }
    })
  }

  loadLastQuestions(){
    this.questionService
      .getLastAnsweredQuestions(new PageFilter({page:1,take:5}))
      .pipe(
        map(response=>response.data as QuestionAnswerWebDto[]),
        map((qa)=>{
          return qa.map(qa=>{
            let questionView:QuestionAnswerView={
              asnwer:{
                answer:qa.answer,
                questionId:qa.questionID,
                replierId:qa.athleteName,
                userName:qa.athleteName,
                photoUrl: qa.athletePhotoURL
              },
              question:{
                categoryId:qa.questionCategoryID,
                question:qa.question,
                questionerId: qa.askerName,
                questionId: qa.athletePhotoURL,
                userName: qa.askerName,
                photoUrl: qa.askerPhotoURL,
              }
            }
            return questionView;
          })
        }),
        tap((questions)=>runInAction(()=>{
          this.lastAnswerQuestionPageContent=questions;
        }))
      )
      .subscribe();
  }

  loadStore(){
    
  }
}