import { Observable, of, mergeMap, from } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../env";
import IdentityStore from "../../../store/user/IdentityStore";
import IAuthService, {
  TokenBase,
  ClientTokenStorageModel,
  UserRegisterDto,
  UserRopcToken,
} from "../../interface/IAuthService";
import { ServiceResponse } from "../../interface/IServiceBase";
import ServiceBase, { ErrorRestResponse, RestResponse } from "./ServiceBase";

class AuthService extends ServiceBase implements IAuthService {
  /**
   *
   */
  constructor(private storeFactory: () => IdentityStore) {
    super(storeFactory());
  }
  protectedLogin = (username: string, pw: string) => {
    var formData = new FormData();
    formData.append(
      "verificationToken",
      new Blob([this.storeFactory().userVerificationCaptcha])
    );
    formData.append("username", username);
    formData.append("password", pw);
    var requestOptions = {
      method: "POST",
      body: formData,
    };

    let req = fetch(
      `${Env.services.auth.host}/api/user/v1/login`,
      requestOptions
    );
    let response = from(req).pipe(
      mergeMap((r) => {
        return from(r.json()).pipe(
          mergeMap((response) => {
            if (
              r.statusText === "Bad Request" ||
              r.statusText === "Internal Server Error" ||
              !r.ok
            ) {
              if (response.Code && response.Code === "MALICIOUS_CLIENT") {
                return of(
                  new ServiceResponse<UserRopcToken>(new UserRopcToken(), [
                    { ...response } satisfies ErrorRestResponse,
                  ])
                );
              }
              let errorResponse = response as ErrorRestResponse;
              return of(
                new ServiceResponse<UserRopcToken>(new UserRopcToken(), [
                  ...errorResponse.errors,
                ])
              );
            }
            return of(new ServiceResponse(response as UserRopcToken, []));
          })
        );
      })
    );
    return response;
  };
  /// thi api triggers the sync process between api and identity store
  notifyLoginApplicationUser(): Observable<ServiceResponse<any>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<any>>({
      url: `${Env.services.api.host}/API/User/v7/NotifyLogin`,
      method: "POST",
      headers,
      body: {},
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  refreshUserToken(): Observable<ServiceResponse<UserRopcToken>> {
    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("client_id", `${Env.services.auth.ropc.id}`);
    urlencoded.append("client_secret", `${Env.services.auth.ropc.secret}`);
    urlencoded.append(
      "refresh_token",
      this.storeFactory().userToken?.refresh_token ?? ""
    );
    let stream = of(this.storeFactory().userToken).pipe(
      mergeMap((userToken) => {
        if (userToken?.refresh_token && userToken?.refresh_token.length > 0)
          return ajax<RestResponse<UserRopcToken>>({
            url: `${Env.services.auth.host}/connect/token`,
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: urlencoded,
          }).pipe(
            (req) => this.handleConnectionRetry(req),
            (req) => this.mapToResponse(req)
          );
        return of();
      })
    );
    return stream;
  }

  loginWithPasswordCredentials(
    username: string,
    pw: string
  ): Observable<ServiceResponse<UserRopcToken>> {
    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("client_id", `${Env.services.auth.ropc.id}`);
    urlencoded.append("client_secret", `${Env.services.auth.ropc.secret}`);
    urlencoded.append("scope", "openid profile offline_access services PGO");
    urlencoded.append("username", username);
    urlencoded.append("password", pw);

    let stream = ajax<RestResponse<UserRopcToken>>({
      url: `${Env.services.auth.host}/connect/token`,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    }).pipe(
      //(req) => this.handleConnectionRetry<UserRopcToken>(req),
      (req) => this.mapToResponse(req)
    );
    return stream;
  }

  getClientCredentialsToken(): Observable<
    ServiceResponse<ClientTokenStorageModel>
  > {
    let urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");
    urlencoded.append("client_id", `${Env.services.auth.client.id}`);
    urlencoded.append("client_secret", `${Env.services.auth.client.secret}`);
    urlencoded.append("scope", "user_register");

    let stream = ajax<RestResponse<ClientTokenStorageModel>>({
      url: `${Env.services.auth.host}/connect/token`,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    }).pipe(
      (req) => this.handleConnectionRetry<ClientTokenStorageModel>(req), //no token renewal needed for login request
      (req) => this.mapToResponse<ClientTokenStorageModel>(req)
    );
    return stream;
  }

  registerProtectedUser(
    request: UserRegisterDto
  ): Observable<ServiceResponse<UserRegisterDto>> {
    var formData = new FormData();
    formData.append(
      "verificationToken",
      new Blob([this.storeFactory().userVerificationCaptcha])
    );
    formData.append("user", new Blob([JSON.stringify(request.user)]));
    formData.append("password", request.password);
    var requestOptions = {
      method: "POST",
      body: formData,
    };

    let req = fetch(
      `${Env.services.auth.host}/api/user/v1/register`,
      requestOptions
    );
    let response = from(req).pipe(
      mergeMap((r) => {
        return from(r.json()).pipe(
          mergeMap((response) => {
            if (
              r.statusText === "Bad Request" ||
              r.statusText === "Internal Server Error" ||
              !r.ok
            ) {
              if (response.Code && response.Code === "MALICIOUS_CLIENT") {
                return of(
                  new ServiceResponse<UserRegisterDto>({}, [
                    { ...response } satisfies ErrorRestResponse,
                  ])
                );
              }
              let errorResponse = response as ErrorRestResponse;
              return of(
                new ServiceResponse<UserRegisterDto>({}, [
                  ...errorResponse.errors,
                ])
              );
            }
            return of(new ServiceResponse(request, []));
          })
        );
      })
    );
    return response;
  }

  registerUser(
    request: UserRegisterDto
  ): Observable<ServiceResponse<UserRegisterDto>> {
    const requestParams = of(request);
    const requestMerge = requestParams.pipe(
      mergeMap((requestMap) => {
        let clientToken = this.storeFactory().clientToken;
        if (clientToken) {
          return ajax<RestResponse<UserRegisterDto>>({
            url: `${Env.services.auth.host}/api/user/v1`,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${clientToken.access_token}`,
            },
            body: requestMap,
          });
        }
        return of();
      })
    );
    return requestMerge.pipe(
      //(req) => this.handleConnectionRetry(req),
      (req) =>
        this.handleTokenRenewal(req, () => {
          this.getClientCredentialsToken().subscribe((result) => {
            if (result.data) {
              this.storeFactory().setClientToken(
                new ClientTokenStorageModel(
                  result.data as ClientTokenStorageModel
                )
              );
            }
          });
        }),
      (req) => this.mapToResponse(req)
    );
  }
}

export { AuthService };

export type { TokenBase as ClientCredToken, UserRegisterDto };
