import dayjs from "dayjs";
import { reaction } from "mobx";
import { debounce, filter, interval, map, Observable, of,mergeMap, concatMap } from "rxjs";
import IdentityStore from "../../../store/user/IdentityStore";
import { UserRopcToken } from "../../interface/IAuthService";
import IUserSessionStorageService from "../../interface/IUserSessionStorageService";

export default class UserSessionLocalStorageService implements IUserSessionStorageService{
  /**
   *
   */
  private readonly storageKey = "user_access_token";
  constructor() {
    
  }
  cleanSession(): Observable<string> {

    localStorage.removeItem(this.storageKey);
    return of(this.storageKey);
  }
  loadUserToken(): Observable<UserRopcToken> {
    return of("request").pipe(
      mergeMap((a)=>{
        let data = localStorage.getItem(this.storageKey);
        if(data){
          let model = JSON.parse(data);
          if(model as UserRopcToken){
            return of(model);
          }
        }
        return of({} as UserRopcToken);
      }));
  }

  saveUserToken(token: UserRopcToken): Observable<UserRopcToken> {
    let stream = of(token)
    .pipe(
      debounce(()=>interval(200)),
      concatMap(token=>{
        return this.loadUserToken().pipe(
          map(storeToken=>{
            if(storeToken.expiresAt && token.expiresAt && storeToken.expiresAt>token.expiresAt){
              return storeToken;
            }else{
              return token;
            }
          })
        )
      })
    )
    stream.subscribe((token)=>{
      localStorage.setItem(this.storageKey,JSON.stringify(token));
    })

    return stream;
  }
}