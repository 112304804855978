import { Observable } from "rxjs";
import { ServiceResponse, PageFilter } from "../../../interface/IServiceBase";
import IEarnMoneyService, { CampaignDetailWebDto, CampaignMoneyEarnDto, CollectedFisDto, FinishFileUploadRequest, FinishFileUploadResponse, FisPostWebRequest, FisPostWebResponse, HistoryListDto } from "../../../interface/app/IEarnMoneyService";
import ServiceBase, { RestResponse } from "../ServiceBase";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";

export default class EarnMoneyRestService extends ServiceBase implements IEarnMoneyService {
    constructor(identityStore:IdentityStore) {
        super(identityStore);
        
      }
    getAllEarning(): Observable<ServiceResponse<CampaignMoneyEarnDto[]>> {
        let headers = this.getBaseHeaders();
        return ajax<RestResponse<CampaignMoneyEarnDto[]>>({
            url: `${Env.services.api.host}/API/Receipt/v7/GetCampaigns`,
            method: "GET",
            headers
          }).pipe(
            (req) => this.handleConnectionRetry(req),
            (req) => this.mapToResponse(req)
          );
    }

    getCampaignDetail(campaignID: string): Observable<ServiceResponse<CampaignDetailWebDto>>{
        let headers = this.getBaseHeaders();
        return ajax<RestResponse<CampaignDetailWebDto>>({
          url: `${Env.services.api.host}/API/Receipt/v7/GetInstructions/${campaignID}`,
          method: "GET",
          headers
        }).pipe(
          (req) => this.handleConnectionRetry(req),
          (req) => this.mapToResponse(req)
        );
      }

    getCollectedFises(request: PageFilter): Observable<ServiceResponse<HistoryListDto>> {
      let headers = this.getBaseHeaders();
      return ajax<RestResponse<HistoryListDto>>({
        url: `${Env.services.api.host}/API/Receipt/v7/GetHistoryList?Page=${request.page}&Take=${request.take}`,
        method: "GET",
        headers
      }).pipe(
        (req) => this.handleConnectionRetry(req),
        (req) => this.mapToResponse(req)
      );
    }
    fileUpload(request: FisPostWebRequest, giftCampaignID:string, groupID:string): Promise<FisPostWebResponse> {
      let headers = this.getBaseHeadersFile();
      const options = {
        method: "POST",
        body: request.file,
        headers
      };
       return fetch(`${Env.services.api.host}/API/Receipt/v7/FileUpload/${giftCampaignID}/${groupID}`, options).then((response) => response.json());
       
    }

    finishFileUpload(request: FinishFileUploadRequest): Observable<ServiceResponse<FinishFileUploadResponse>> {
      let headers = this.getBaseHeaders();
      return ajax<RestResponse<FinishFileUploadResponse>>({
        url: `${Env.services.api.host}/API/Receipt/v7/FileUploadFinish`,
        method: "POST",
        body:request,
        headers
      }).pipe(
        (req) => this.handleConnectionRetry(req),
        (req) => this.mapToResponse(req)
      );
    }
}