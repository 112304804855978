import { extendObservable, makeAutoObservable, makeObservable, observable } from "mobx";
import { chunkProcessor, createViewModel, IViewModel, ObservableGroupMap, ViewModel } from "mobx-utils";
import xss from 'xss';
import IFormInputModel, {
  FieldValidationResult,
  FieldValidationResultWithField,
  FormInputs,
  FormLabelData,
  FormSetupValues,
  FormValidationResult,
} from "./IFormInputModel";

abstract class FormInputModel implements IFormInputModel {
  private _form: FormInputs;
  private labels: FormLabelData;
  private _validationResult: FormValidationResult;
  private updateChunkItems: { field: string; value: any }[] = [];
  public fieldKeys: string[] = [];
  private readonly defaultFormSetupValuesCopy :string;
  constructor(input: FormSetupValues) {
    this.defaultFormSetupValuesCopy=JSON.stringify(input);
    this._form = observable<FormInputs>(input.defaultFormInputValues);
    this.labels = observable<FormLabelData>(input.formLabels);
    this.fieldKeys = input.fieldKeys.filter((a) => a?.length > 0);
    this._validationResult = observable<FormValidationResult>({
      isValid: false,
      errors: {},
      isProcessing: false,
    });
  }

  addValidationDynamic(value: string, key: string, type:string){};
  

  get validation(): FormValidationResult {
    let inCompleteValidation = this.requiredMissingFields.length > 0;

    let response = observable<FormValidationResult>({
      ...this._validationResult,
      isValid: !inCompleteValidation && this.updateChunkItems.length == 0,
      isProcessing: this.updateChunkItems.length > 0,
    });
    return response;
  }

  get validationErrorList(): FieldValidationResultWithField[] {
    return this.requiredMissingFields.map((field) => {
      let resp:FieldValidationResultWithField = { field, errors: this.validation.errors[field]?.errors,requiredText:""};
      resp.requiredText = (resp.errors && resp.errors.length>0)?"":this.labels.requiredKeys[field]
      return resp;
    });
  }

  
  get requiredMissingFields() {
    return Object.keys(this.labels.requiredKeys)
      .slice()
      .flatMap((requiredKeyd) => {
        let keys = []
        if (!(this._validationResult.errors[requiredKeyd]?.invalid === false)) {
          keys.push(requiredKeyd)
        }
        return keys;
      });
  }

  initForm() {
    
    extendObservable(this, { ...this }); //important to track properties!!!
    this.ProcessFieldUpdates();
    this.DoFieldValidation();
  }

  clearForm(){
    this._form = observable((JSON.parse(this.defaultFormSetupValuesCopy) as FormSetupValues).defaultFormInputValues);
    this._validationResult = observable<FormValidationResult>({
      isValid: false,
      errors: {},
      isProcessing: false,
    });
    this.ProcessFieldUpdates();
    this.DoFieldValidation();
  }


  private DoFieldValidation() {
    this.fieldKeys.forEach((field) => this.validateFieldInput(field));
  }

  updateField(field: string, value: any) {
    if (this.fieldKeys.filter((a) => a === field).length === 0) {
      console.warn("Form has no defined field", field);
    }
    if(typeof value === "string"){
      let protectedValue = xss(value);
      this.updateChunkItems.push({ field, value: protectedValue});
    }else
    this.updateChunkItems.push({ field, value });
  }
  private ProcessFieldUpdates() {
    chunkProcessor(
      this.updateChunkItems,
      (items) => {
        const groupItemByField = new ObservableGroupMap(
          observable(items),
          (a) => a.field
        );
        groupItemByField.forEach((item) => {
          let { field, value } = item[item.length - 1];
          this._form[field] = value;
        });
        this.DoFieldValidation();
      },
      0,
      0
    );
  }
  private validateFieldInput(field: string) {
    let validationFunction = this.labels.validation[field];
    if (validationFunction) {
      if (!(this._form[field] == undefined)) {
        let validationResult = validationFunction(this._form[field], this._form);
        this._validationResult.errors[field] = validationResult;
      }
    }
    return { invalid: false };
  }

  fieldValidation(field: string): FieldValidationResult {
    if (!this._validationResult.errors[field]) {
      return { invalid: false };
    }
    return this._validationResult.errors[field];
  }

  formField(field: string): string {
    return this._form[field];
  }
  get formLabel(): FormLabelData {
    return this.labels;
  }

  get formData() {
    return this._form;
  }
}

export default FormInputModel;
