import { forkJoin, map, mergeMap, Observable, of, toArray } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IArticleService, {
  ArticleDetailWebDto,
  ArticleWebDto,
} from "../../../interface/app/IArticleService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse, ServiceHeaderBase } from "../ServiceBase";

export default class ArticleRestService
  extends ServiceBase
  implements IArticleService
{
  /**
   *
   */
  constructor(identityStore: IdentityStore) {
    super(identityStore);
  }
  getArticleDetail(articleId: string): Observable<ServiceResponse<ArticleDetailWebDto>>{
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<ArticleDetailWebDto>>({
      url: `${Env.services.api.host}/API/Article/v7/GetDetail/${articleId}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getArticles(
    pagination?: PageFilter
  ): Observable<ServiceResponse<ArticleWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<ArticleWebDto[]>>({
      url: `${Env.services.api.host}/API/Article/v7/GetArticles?Page=${
        pagination?.page ?? 1
      }&Take=${pagination?.take ?? 9}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }


  

}
