import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import IRewardService, { CampaignWebDto, ChoicePostWebRequest, ChoicePostWebResponse, CodePostResponseWebDto, CodePostWebRequest, CouponListItemWebDto } from "../../../interface/app/IRewardService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class RewardRestService extends ServiceBase implements IRewardService {

  /**
   *
   */
  constructor({ auth }: { auth: IdentityStore; }) {
    super(auth);
  }
  getCollectedCoupons(request: PageFilter): Observable<ServiceResponse<CouponListItemWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<CouponListItemWebDto[]>>({
      url: `${Env.services.api.host}/API/Reward/v7/EarnedCoupon?Page=${request.page}&Take=${request.take}`,
      method: "GET",
      headers,
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

  getAllCampaigns(): Observable<ServiceResponse<CampaignWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<CampaignWebDto[]>>({
      url: `${Env.services.api.host}/API/Reward/v7/GetCampaigns`,
      method: "GET",
      headers,
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  codePost(request: CodePostWebRequest): Observable<ServiceResponse<CodePostResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<CodePostResponseWebDto>>({
      url: `${Env.services.api.host}/API/Reward/v7/CodePost`,
      method: "POST",
      headers,
      body: request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  choicePost(request: ChoicePostWebRequest): Observable<ServiceResponse<ChoicePostWebResponse>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<ChoicePostWebResponse>>({
      url: `${Env.services.api.host}/API/Reward/v7/ChoicePost`,
      method: "POST",
      headers,
      body: request
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
}
