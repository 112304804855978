import { catchError, delay, map, Observable, of, retry, tap } from "rxjs";
import { AjaxError, AjaxResponse, AjaxTimeoutError } from "rxjs/ajax";
import IdentityStore from "../../../store/user/IdentityStore";
import { ServiceError, ServiceResponse } from "../../interface/IServiceBase";

export interface ErrorRestResponse {
  errors: RestError[];
  refId?: string;
  error?: Error;
  error_description?: string;
}
export interface ServiceHeaderBase {
  Authorization?: string;
  "Content-Type": string;
}
export interface RestError {
  Code: string;
  text: string;
}

export class RestResponse<T> {
  constructor(data: Partial<T>, error?: Partial<ErrorRestResponse>) {
    this.data = data;
    this.error = error ?? {};
  }

  error: Partial<ErrorRestResponse>;
  data: Partial<T>;
}

abstract class ServiceBase {
  identityStore: IdentityStore;
  constructor(identityStore: IdentityStore) {
    this.identityStore = identityStore;
  }

  getBaseHeaders(): ServiceHeaderBase {
    let headers: ServiceHeaderBase = {
      Authorization: `Bearer ${
        this.identityStore.userToken?.access_token ?? ""
      }`,
      "Content-Type": "application/json",
    };
    if (!this.identityStore.isUserAuthenticated) {
      delete headers["Authorization"];
    }
    return headers;
  }

  getBaseHeadersFile() {
    let headers = {
      Authorization: `Bearer ${
        this.identityStore.userToken?.access_token ?? ""
      }`,
    };
    return headers;
  }


  handleConnectionRetry<T>(
    request: Observable<AjaxResponse<RestResponse<T>>>
  ): Observable<AjaxResponse<RestResponse<T>>> {
    return request.pipe(
      retry({
        count: 6,
        delay: (error, retryCount) => {
          if (
            error instanceof AjaxTimeoutError ||
            (error instanceof AjaxError &&
              (error.status === 0 ||
                error.status === 503 ||
                error.status === 502))
          ) {
            console.warn("Server connection can not be established");
            return of(error).pipe(delay(retryCount * 2000)); //retry
          } else {
            throw error;
          }
        },
      })
    );
  }
  handleTokenRenewal<T>(
    request: Observable<AjaxResponse<RestResponse<T>>>,
    renewAccessToken: () => void
  ): Observable<AjaxResponse<RestResponse<T>>> {
    return request.pipe(
      retry({
        count: 6,
        delay: (error, retryCount) => {
          if (error instanceof AjaxError && error.status === 401) {
            console.warn("Waiting to renew auth token");
            renewAccessToken();
            return of(error).pipe(delay(retryCount * 2000)); //retry
          } else {
            throw error;
          }
        },
      })
    );
  }

  onInvalidSession401<T>(
    request: Observable<ServiceResponse<T>>,
    onInvalidSession: (resp: ServiceResponse<T>) => void
  ): Observable<ServiceResponse<T>> {
    return request.pipe(
      tap((a) => {
        if (
          a.hasErrors() &&
          a.errors.filter((a) => a.Code === "AUTH401").length > 0
        ) {
          onInvalidSession(a);
        }
      })
    );
  }

  mapToResponse<T>(
    request: Observable<AjaxResponse<RestResponse<T>>>,
    mapper?: (restResponse: RestResponse<T>) => ServiceResponse<T>
  ): Observable<ServiceResponse<T>> {
    return request.pipe(
      map((data) => {
        let result = data.response;
        if (mapper) {
          return mapper(result);
        }
        let response = data.response as unknown;
        let serviceResponse: ServiceResponse<T>;
        if (Array.isArray(data.response) || typeof response === "object") {
          serviceResponse = new ServiceResponse(response as T, []);
        } else {
          serviceResponse = new ServiceResponse({}, []);
        }
        return serviceResponse;
      }),
      catchError((error) => {
        let serviceResponse: ServiceResponse<T> = new ServiceResponse({}, []);
        if (error instanceof AjaxError && error.status === 400) {
          if (error.response) {
            if (
              typeof error.response === "object" &&
              error.response.error === "invalid_grant"
            ) {
              serviceResponse.errors.push({
                Code: `AUTH${error.status}`,
                text: "Geçersiz oturum",
              });
            } else {
              let applicationError: ErrorRestResponse = error.response;
              serviceResponse.errors = [
                ...applicationError.errors.map((restError) => {
                  return {
                    Code: restError.Code,
                    text: restError.text,
                  } as ServiceError;
                }),
              ];
            }
          } else {
            serviceResponse.errors.push({
              Code: "RQSTINV",
              text: "Geçersiz istek",
            });
          }
        } else if (
          error instanceof AjaxTimeoutError ||
          (error instanceof AjaxError && error.status === 0)
        ) {
          serviceResponse.errors.push({
            Code: "CNN1",
            text: "Lütfen internet bağlantınızı kontrol ediniz",
          });
        } else if (
          error instanceof AjaxError &&
          (error.status === 401 || error.status === 403)
        ) {
          serviceResponse.errors.push({
            Code: `AUTH${error.status}`,
            text: "Geçersiz oturum",
          });
        } else {
          console.error("Error", error);
          serviceResponse.errors.push({
            Code: "UNEXP1",
            text: "Beklenmedik hata",
          });
        }
        return of(serviceResponse);
      })
    );
  }
}

export default ServiceBase;
