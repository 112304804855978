import { makeAutoObservable, reaction, runInAction } from "mobx";
import { map } from "rxjs";
import { RemainingView } from "../../model/Model";
import UserProfileRestService from "../../services/implementation/rest/app/UserProfileRestService";
import { CompleteInvitationWebDto, Friend, GetFriendListResponseWebDto, RemaininTaskWebDto, UserReferralCodeWebDto } from "../../services/interface/app/IUserProfileService";
import { RootContextWrapper } from "../RootContext";

type FaqModalState = { isDataLoaded: boolean, markDownData: string, isModalOpen: boolean };
type FrModalState = { isModalOpen: boolean };


const FAQ_DATA = `
# Sıkça Sorulan Sorular
------
**İstediğim ödül tükenmiş, ne zaman yenilenir?**

Sevgili kullanıcımız Adım Adım Kazan platformumuza gösterdiğiniz ilgi için teşekkür ederiz. Ekiplerimiz haftalık olarak mevcut ödüllerin durumlarını kontrol edip, yeni ödüller eklemektedir. Tükenen ödüller en kısa süre içerisinde yenilenecektir veya yerlerine farklı ödüller yerleştirilecektir.

**Görevleri yapıyorum ancak puanım artmıyor, neden?**

Sevgili kullanıcımız, Adım Adım Kazan platformumuza gösterdiğiniz ilgi için teşekkür ederiz. Platformumuzdaki görevlerde günlük kotamız bulunuyor ve siz de bu sınıra ulaşmış olabilirsiniz. Daha fazla puan kazanmak için platformumuzu her gün ziyaret etmeyi ve görevlerinizi tamamlamayı lütfen unutmayın. Anlayışınız için teşekkür ederiz.

**Şifremi unuttum, nasıl yenileyebilirim?**

Sevgili kullanıcımız Adım Adım Kazan platformumuza gösterdiğiniz ilgi için teşekkür ederiz. Anasayfa'da sağ üstte bulunan "Giriş Yap" butonuna tıklayarak Giriş sayfasına ulaşınız. Açılan sayfada kırmızı rankle yazılan "Şifrenizi mi unuttunuz" butonuna tıklayarak şifre sıfırlama sayfasına erişebilirsiniz. Bu sayfa üzerinden yeni bir şifre belirleyerek belirlediğiniz yeni şifreniz ile Adım Adım Kazan platformuna giriş yapabilirsiniz. Anlayışınız için teşekkür ederiz.

**Hediyem tarafıma ulaşmadı, ne zaman gelir?**

Sevgili kullanıcımız, Adım Adım Kazan platformumuza gösterdiğiniz ilgi için teşekkür ederiz. Fiziki ödüllerin teslimatı, ödülün kazanılmasından sonraki 1 aylık süreç içerisinde gerçekleştirilmektedir. Ekibimiz bugüne kadar kazanılan tüm ödüllerin gönderimine başlamış olup, ödülünüzün en kısa sürede size ulaştırılması için çalışmalarını sürdürmektedir. Her Cuma kazanılan ödüller ve kazanan bilgileri ilgili ekiplerle paylaşılarak gerekli aksiyonlar alınmatkadır. Anlayışınız için teşekkür ederiz.

**Yeni görevler gelecek mi?**

Sevgili kullanıcımız, Adım Adım Kazan platformumuza gösterdiğiniz ilgi için teşekkür ederiz. Ekibimiz platformumuza, en kısa süre içerisinde yeni görevler eklemek için çalışmalarını sürdürmektedir. Anlayışınız için teşekkür ederiz.
`


export default class ActivityStore {

  remaininModalViewData: RemainingView[] = [
  ];

  frInviteCode: string="";
  frInvitePoints:number=200;
  frCompleteFirstDutyPoint:number = 100;
  completeInvitationResult=false;


  isRemaningModalOpen: boolean = false;
  rootContext: RootContextWrapper;
  userService: UserProfileRestService;
  private _faqModalState: FaqModalState = { isDataLoaded: false, markDownData: "", isModalOpen: false }
  private _frModalState: FrModalState = { isModalOpen: false };
  constructor(rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootContext = rootContext;
    this.userService = new UserProfileRestService(rootContext.identityStore);
  }

  loadStore() {
    reaction(
      () => this.rootContext.userStore.userScore,
      (cur, prev) => {
        let currentScore = Number(cur) ?? 0;
        let prevScore = Number(prev) ?? 0;
        if (currentScore > prevScore && this.rootContext.userStore.identityStore.isUserAuthenticated) {
          this.openRemaningModal();
        }
      }
    );
  }

  closeModal() {
    this.isRemaningModalOpen = false;
  }

  openRemaningModal() {
    this.userService.getRemaningTasks()
      .pipe(
        map(t => t.data as RemaininTaskWebDto[])
      ).subscribe(t => runInAction(() => {
        this.remaininModalViewData = t.map(task => {
          let item: RemainingView = {
            isActive: !task.isDisabled,
            left: task.howManyLeft,
            title: task.title
          };
          return item;
        })
        this.isRemaningModalOpen = true;
      }))
  }

  get isFaqModalOpen() {
    return this._faqModalState.isModalOpen;
  }

  get isFaqDataFetched() {
    return this._faqModalState.isDataLoaded;
  }

  get faqMdData() {
    return this._faqModalState.markDownData;
  }

  get isFrModalOpen() {
    return this._frModalState.isModalOpen;
  }

  openFaqModal() {
    this._faqModalState.isModalOpen = true;
    this._faqModalState.isDataLoaded = true;
    this._faqModalState.markDownData = FAQ_DATA;
  }
  closeFaqModal() {
    this._faqModalState.isModalOpen = false;
  }

  openFriendReferralModal() {
    this.getReferralCode();
  }
  closeFriendReferralModal() {
    this._frModalState.isModalOpen = false;
  }

  getReferralCode(){
    this.userService.getUserReferralCode()
    .pipe(
      map(t => t.data as UserReferralCodeWebDto)
    ).subscribe(t => runInAction(() => {
      this.frInviteCode = t.inviteCode;
      this.frInvitePoints = t.points;
      this.frCompleteFirstDutyPoint = t.pointsOfFriendCompletedADuty;
      this._frModalState.isModalOpen = true;
      })
    );
    }

    completeInvitation(code:string){
      var request = {code:code};
      this.userService.completeInvitation(request)
      .pipe(
        map(t => t.data as CompleteInvitationWebDto)
      ).subscribe(t => runInAction(() => {
        this.completeInvitationResult = t.isSuccess;
        })
      );
      }


  }

