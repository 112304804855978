export interface ServiceError {
  Code: string;
  text: string;
}

export class PageFilter {
  /**
   *
   */
  constructor(data?: Partial<PageFilter>) {
    Object.assign(this, { ...data });
  }
  page: number = 1;
  take: number = 20;
  toString(): string {
    return `Page=${this.page}&Take=${this.take}`;
  }
}

export class ServiceResponse<T> {
  constructor(data: Partial<T>, errors?: any[]) {
    if (Array.isArray(data)) this.data = [...data];
    else this.data = { ...data };
    this.errors = errors ?? [];
    this.hasErrors = () => {
      return this.errors.length > 0;
    };
  }

  errors: ServiceError[] = [];
  data: Partial<T> | T[];

  hasErrors: () => boolean;
}
