import FormInputModel from "../FormInputModel";
import IFormInputModel, { FormInputs } from "../IFormInputModel";

class GiftReservationFormModel extends FormInputModel implements IFormInputModel {
  
  emptyValidator=(value:string,keyName:string)=>{
    let errors: string[] = [];
            if (value.trim().length < 2) {
              errors.push(`${keyName} alanı boş bırakılamaz`);
            }
    return { invalid: errors.length > 0, errors };
  }
  
  constructor() {
    super({
      fieldKeys: [
        "firstName",
        "lastName",
        "phone",
        "city",
        "district",
        "postalCode",
        "address",
        "agreement",
        "gsm",
      ],
      formLabels: {
        data: {
          
        },
        requiredKeys:{
          firstName:"İsim alanı boş bırakılamaz",
          lastName:"Soyad alanı boş bırakılamaz",
          phone:"Telefon alanı boş bırakılamaz",
          city:"Şehir alanı boş bırakılamaz",
          district:"İlçe bilgisi boş bırakılamaz",
          postalCode:"Posta Kodu bilgisi boş bırakılamaz",
          address:"Adres bilgisi boş bırakılamaz",
          agreement: "Onay metni zorunludur",
          gsm:"Gsm bilgisi boş bırakılamaz",
          mail:"Mail bilgisi boş bırakılamaz",
          // kvkk:"KVKK metin onayı gereklidir",
          // agreement:"Sözleşme metin onayı gereklidir",
        },
        validation: {
          firstName:(value)=>this.emptyValidator(value,"firstName"),
          lastName:(value)=>this.emptyValidator(value,"lastName"),
          phone:(value)=>this.emptyValidator(value,"phone"),
          city:(value)=>this.emptyValidator(value,"city"),
          district:(value)=>this.emptyValidator(value,"district"),
          postalCode:(value)=>this.emptyValidator(value,"postalCode"),
          address:(value)=>this.emptyValidator(value,"address"),
          gsm:(value)=>this.emptyValidator(value,"gsm"),
          mail:(value)=>this.emptyValidator(value,"mail"),
          text:(value)=>this.emptyValidator(value,"text"),
          agreement:(value)=>{
            let errors: string[] = [];
            if (value === false) {
              errors.push("Onay metni zorunludur");
            }
            return { invalid: errors.length > 0, errors };
          }
        },
      },
      defaultFormInputValues: {
        kvkk:false,
        agreement:false,
      },
    });
    this.initForm();
  }

  


}

export default GiftReservationFormModel;
