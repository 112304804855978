import { makeAutoObservable, runInAction, action, reaction, toJS } from "mobx";
import { GiftLevelViewItem, GiftViewItem } from "../../model/Model";
import GiftRestService from "../../services/implementation/rest/app/GiftRestService";
import IGiftService, {
  GiftLevelDetailWebDto,
  GiftLevelMetaWebDto,
  ReservationCompleteResponseDto,
  ReservationCompleteWithAddressResponseDto,
  ReservationResponseWebDto,
  ValidationCompleteResponseDto,
  FieldValue
} from "../../services/interface/app/IGiftService";
import { RootContextWrapper } from "../RootContext";
import { forkJoin, map, mergeMap, last, tap, toArray, of } from "rxjs";
import GiftReservationFormModel from "../../model/view/form/user/GiftReservationFormModel";
import GiftValidationFormModel from "../../model/view/form/user/GiftValidationFormModel";
import IFormInputModel from "../../model/view/form/IFormInputModel";
import UserStore from "./UserStore";
import { GiftStatus } from "../../model/view/gift/GiftItem";

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export enum GiftTypes {
  BolBol = "BolBol",
  Undefined = "Undefined",
}
export default class GiftStore {
  _giftService: IGiftService;

  giftLevelMetaContent: GiftLevelViewItem[] = [];
  giftItemsPageContent: {
    levelDetail: GiftViewItem[];
    levelMeta: GiftLevelViewItem;
  }[] = [];

  reserVationForm: IFormInputModel;
  validationForm: IFormInputModel;

  selectedGiftItemId: string = "NA";
  selectedGiftItemCategoryId: string = "NA";



  private _reservationStatus: {
    errorMessage: string;
    transcationId: string;
    phase: "INIT" | "RESERVED" | "SUBMITTED";
    isCompletedSuccessFully: boolean;
    reservationResponse: string;
    progress:"STARTED"|"COMPLETED"|"FAILED"|"INIT";
  } = {
    errorMessage: "",
    transcationId: EMPTY_GUID,
    phase: "INIT",
    isCompletedSuccessFully: true,
    reservationResponse: "Tebrikler ödülü kaptın!",
    progress:"INIT"
  };
/*
  private _validationStatus: {
    errorMessage: string;
    transcationId: string;
    phase: "INIT" | "SUBMITTED";
    isCompletedSuccessFully: boolean;
    applyResponse: string;
  } = {
    errorMessage: "",
    transcationId: EMPTY_GUID,
    phase: "INIT",
    isCompletedSuccessFully: true,
    applyResponse: "Tebrikler ödülü kaptın!",
  };
*/
  private _userStore: UserStore;

  constructor(rootContext: RootContextWrapper) {
    this.reserVationForm = new GiftReservationFormModel();
    this.validationForm = new GiftValidationFormModel();

    makeAutoObservable(this, {}, { autoBind: true });
    this._giftService = new GiftRestService(rootContext.identityStore);
    this._userStore = rootContext.userStore;
  }

  private mapToLevelMetaViewModel(
    level: GiftLevelMetaWebDto
  ): GiftLevelViewItem {
    let item: GiftLevelViewItem = {
      id: level.id,
      photoUrl: level.photoURL,
      title: level.title,
      point: level.point,
    };
    return item;
  }

  private mapToGiftViewModel(
    level: GiftLevelMetaWebDto,
    details: GiftLevelDetailWebDto[]
  ): GiftViewItem[] {

    return details.map((detail) => {
      let item: GiftViewItem = {
        id: detail.giftID,
        code: detail.code ?? "",
        completedNotCodeText: "Ödülü Aldın",
        remaningPoint: this.mapRemaininPoint(level),
        targetPoint: level.point.toString(),
        title: detail.title,
        addressRequired: detail.isAddressRequired,
        photoURL: detail.photoURL,
        ...this.mapGiftStatus(detail, level),
        description: detail.description,
        giftType: detail.giftType,
        customFields: detail.customFields,
        IsClaimable: detail.isClaimable,
        status: detail.isClaimable ? "INPROG" : "NOTAVAILABLE",
      };
      if (detail.isAddressRequired) {
        item.code = "";
        item.completedNotCodeText = "Ödülünüz size iletilecektir";
      }
      return item;
    });
  }

  private mapRemaininPoint(level: GiftLevelMetaWebDto): number {
    return level.point > this._userStore.userScore
      ? level.point - this._userStore.userScore
      : 0;
  }

  private mapGiftStatus(
    webModel: GiftLevelDetailWebDto,
    level: GiftLevelMetaWebDto
  ): { status: GiftStatus; notAvailableMessage: string } {
    let result: { status: GiftStatus; notAvailableMessage: string } = {
      notAvailableMessage: `${level.point} Olimpik Puan'a ulaş!`,
      status: "NOTAVAILABLE",
    };

    if(!webModel.isClaimable){
      result.notAvailableMessage = "";
      result.status = "NOTAVAILABLE";
    } else {
    if (webModel.isClaimed) {
      result.status = "COMPLETED";
      result.notAvailableMessage = "";
    } else {
      if (this._userStore.userScore >= level.point) {
        result.status = "INPROG";
        result.notAvailableMessage = "";
      }
    }
  }

    if (!webModel.isClaimed && this._userStore.userLevelState) {
      let key: string = `isClaimed_${webModel.level.toString()}`;
      let claimLevelState = JSON.parse(
        JSON.stringify(this._userStore.userLevelState)
      )[key] as boolean;
      if (claimLevelState) {
        //result.status = "NOTAVAILABLE";
        result.notAvailableMessage =
          "Her seviye için en fazla bir ödül alınabilir.";
      }
    }
    return result;
  }

  loadStore() {}

  setInitialReservationState() {
    runInAction(() => {
      this._reservationStatus = {
        errorMessage: "",
        isCompletedSuccessFully: true,
        phase: "INIT",
        transcationId: EMPTY_GUID,
        reservationResponse: "",
        progress:"INIT"
      };
      this.selectedGiftItemId = EMPTY_GUID;
      this.selectedGiftItemCategoryId = EMPTY_GUID;
      if (
        this._reservationStatus.phase === "SUBMITTED" &&
        this._reservationStatus.isCompletedSuccessFully
      ) {
        this.reserVationForm.clearForm();
        this.validationForm.clearForm();
      }
    });
  }

  /*
  setInitialValidationState() {
    runInAction(() => {
      this._validationStatus = {
        errorMessage: "",
        isCompletedSuccessFully: true,
        phase: "INIT",
        transcationId: EMPTY_GUID,
        applyResponse: "",
      };
      this.selectedGiftItemId = EMPTY_GUID;
      this.selectedGiftItemCategoryId = EMPTY_GUID;
      if (
        this._reservationStatus.phase === "SUBMITTED" &&
        this._reservationStatus.isCompletedSuccessFully
      ) {
        this.validationForm.clearForm();
      }
    });
  }
  */

  startReservationRequest() {
    this._giftService
      .startReservation({ giftID: this.selectedGiftItem?.id ?? EMPTY_GUID })
      .pipe(
        map((a) => a.data as ReservationResponseWebDto),
        tap((r) =>
          runInAction(() => {
            if (r.isSuccess) {
              this._reservationStatus.transcationId = r.reservationUniqID;
              this._reservationStatus.phase = "RESERVED";
              this._reservationStatus.isCompletedSuccessFully = true;
            } else {
              this._reservationStatus.errorMessage = r.errorMessage;
              this._reservationStatus.phase = "INIT";
              this._reservationStatus.transcationId = EMPTY_GUID;
              this._reservationStatus.isCompletedSuccessFully = false;
            }
          })
        )
      )
      .subscribe({
        error: (e) => {
          this.setInitialReservationState();
        },
      });
  }

  loadGiftLevels() {
    this._giftService
      .getLevelsMeta()
      .pipe(
        tap((res) =>
          runInAction(() => {
            let metaData = res.data as GiftLevelMetaWebDto[];
            this.giftLevelMetaContent = metaData.map((level, index) =>
              this.mapToLevelMetaViewModel(level)
            );
          })
        ),
        mergeMap((levelMeta) => {
          let result = levelMeta.data
            .map((level) => level as GiftLevelMetaWebDto[])
            .flatMap((level) => level)
            .map((level) =>
              this._giftService.getLevelDetails(level.id).pipe(
                map((levelMeta) => levelMeta.data as GiftLevelDetailWebDto[]),
                map((levelDetail) => {
                  return { levelDetail, levelMeta: level };
                })
              )
            );
          return forkJoin(result);
        }),
        last(),
        tap((detailMerge) =>
          runInAction(() => {
            let mapping = detailMerge.map((detailRest, index) => {
              let pageView: {
                levelDetail: GiftViewItem[];
                levelMeta: GiftLevelViewItem;
              } = {
                levelMeta: this.mapToLevelMetaViewModel(detailRest.levelMeta),
                levelDetail: this.mapToGiftViewModel(
                  detailRest.levelMeta,
                  detailRest.levelDetail
                ),
              };
              return pageView;
            });
            this.giftItemsPageContent = [...mapping];
          })
        )
      )
      .subscribe();
  }

  get selectedGiftItem(): GiftViewItem | undefined {
    return this.giftItemsPageContent
      .flatMap((a) => a.levelDetail)
      .filter((a) => a.id === this.selectedGiftItemId)[0];
  }

  get selectedGiftItemCategory(): GiftLevelViewItem | undefined {
    return this.giftItemsPageContent
      .flatMap((a) => a.levelMeta)
      .filter((a) => a.id === this.selectedGiftItemCategoryId)[0];
  }

  get reservationStatus() {
    return this._reservationStatus;
  }

  get isReservationModalOpen(): boolean {
    const modalState =
      this.selectedGiftItem != undefined &&
      this.selectedGiftItem.addressRequired &&
      this.selectedGiftItem.giftType !=  GiftTypes.BolBol &&
      this.reservationStatus.phase == "RESERVED";
    return modalState;
  }

  get isValidationModalOpen(): boolean {
    const modalState =
      this.selectedGiftItem != undefined && 
      !this.selectedGiftItem.addressRequired &&
      this.selectedGiftItem.giftType ==  GiftTypes.BolBol &&
      this.reservationStatus.phase == "RESERVED"
    return modalState;
  
  }

  get isConfirmationModalOpen(): boolean {
    const modalState =
      this.selectedGiftItem != undefined &&
      !this.selectedGiftItem.addressRequired &&
      this.selectedGiftItem.giftType != GiftTypes.BolBol &&
      this.reservationStatus.phase == "RESERVED";
    return modalState;
  }

  setGiftSelectedItem = action((giftId: string, categoryId: string) => {
    this.selectedGiftItemId = giftId;
    this.selectedGiftItemCategoryId = categoryId;
  });

  submitValidationAndCompleteApply = action(() => {
    if (this.selectedGiftItem) {
      if (this.selectedGiftItem.giftType == GiftTypes.BolBol && this.validationForm.validationErrorList.length == 0) {
        this.reservationStatus.progress = "STARTED";
        var fields = this.selectedGiftItem.customFields.map(field => {
          {
              return {
                fieldID:field.id,
                value:this.validationForm.formField(field.title)
              }
            }
        }
          )
        this._giftService
          .completeReservation({
            giftID: this.selectedGiftItem.id,
            reservationUniqID: this.reservationStatus.transcationId,
            fields:[...fields]
          })
          .pipe(
            map((a) => a.data as ValidationCompleteResponseDto),
            tap((r) =>
              runInAction(() => {
                this.reservationStatus.phase = "SUBMITTED";
                this.reservationStatus.isCompletedSuccessFully = r.isSuccess;
                this.reservationStatus.errorMessage = r.message;
                this.reservationStatus.reservationResponse = r.message;
                this.reservationStatus.progress = "COMPLETED";
              })
            )
          )
          .subscribe();
            }
          }
  })

  submitReservationRequest = action(() => {
    if (this.selectedGiftItem) {
      if (this.selectedGiftItem.addressRequired) {
        this._giftService
          .completeReservationWithAddress({
            address: this.reserVationForm.formField("address"),
            city: this.reserVationForm.formField("city"),
            district: this.reserVationForm.formField("district"),
            firstName: this.reserVationForm.formField("firstName"),
            lastName: this.reserVationForm.formField("lastName"),
            postalCode: this.reserVationForm.formField("postalCode"),
            phoneNumber: this.reserVationForm.formField("phone"),
            giftID: this.selectedGiftItem.id,
            reservationUniqID: this.reservationStatus.transcationId,
          })
          .pipe(
            map((a) => a.data as ReservationCompleteWithAddressResponseDto),
            tap((r) =>
              runInAction(() => {
                this._reservationStatus.phase = "SUBMITTED";
                this._reservationStatus.isCompletedSuccessFully = r.isSuccess;
                this._reservationStatus.errorMessage = r.message;
                this._reservationStatus.reservationResponse = r.message;
              })
            )
          )
          .subscribe();
      } else {
        this._giftService
          .completeReservation({
            giftID: this.selectedGiftItem.id,
            reservationUniqID: this.reservationStatus.transcationId,
            fields:[]
          })
          .pipe(
            map((a) => a.data as ReservationCompleteResponseDto),
            tap((r) =>
              runInAction(() => {
                this._reservationStatus.phase = "SUBMITTED";
                this._reservationStatus.isCompletedSuccessFully = r.isSuccess;
                this._reservationStatus.errorMessage = r.message;
                this._reservationStatus.reservationResponse = r.message;
              })
            )
          )
          .subscribe();
      }
    }
  });
}
