import { makeAutoObservable, reaction, runInAction } from "mobx";
import { map } from "rxjs";
import { QuestionRestToViewModel } from "../../model/mapping/QuestionMapping";
import { AthletCategoryView} from "../../model/Model";
import AthletView from "../../model/view/athlet/Athlet";
import { QuestionAnswerView } from "../../model/view/question/Question";
import AthletRestService from "../../services/implementation/rest/app/AthletRestService";
import QuestionRestService from "../../services/implementation/rest/app/QuestionRestService";
import IAthletService, { AthletCategoryWebDto, AthletWebDto } from "../../services/interface/app/IAthletService";
import IQuestionService, { QuestionAnswerWebDto } from "../../services/interface/app/IQuestionService";
import { PageFilter } from "../../services/interface/IServiceBase";
import { RootContextWrapper } from "../RootContext";

export default class CategoryAthletStore{
  athletCategoryPageContent: AthletCategoryView[]=[];
  pageSelectedCategory:AthletCategoryView={categoryId:"NA",name:"NA"}
  pageAthletContent: AthletView[]=[];
  pageAthletPageFilter:PageFilter=new PageFilter({page:1});
  pageAthletCategoryLastAnsweredQuestions:QuestionAnswerView[]=[];

  questionService:IQuestionService;
  athletService:IAthletService;
  constructor(rootContext:RootContextWrapper) {
    makeAutoObservable(this,{},{autoBind:true});
    this.athletService=new AthletRestService(rootContext.identityStore);
    this.questionService=new QuestionRestService(rootContext.identityStore);
  }


  setpageActiveCategory(categoryId:string){
    this.pageSelectedCategory=this.athletCategoryPageContent.filter(a=> a.categoryId===categoryId)[0];
  }

  fetchmoreAthlets(){
    this.pageAthletPageFilter = {
      page:this.pageAthletPageFilter.page+1,
      take:this.pageAthletPageFilter.take
    }
    this.fetchCategoryAthlets(
      this.pageSelectedCategory.categoryId,
      this.pageAthletPageFilter,
      (data)=>{
        this.athletCategoryPageContent.push(...data);
      });
  }

  loadAthletCategories(){
    this.athletService.getAthletCategories(this.pageAthletPageFilter)
    .pipe(
      map((rest)=>rest.data as AthletCategoryWebDto[]),
      map(data=>{
        return data.map(item=>{
          let viewModel:AthletCategoryView={
            categoryId:item.id,
            name: item.branch
          }
          return viewModel;
        })
      })
    )
    .subscribe((response)=>runInAction(()=>{
      this.athletCategoryPageContent=response;
      this.pageSelectedCategory=response[0];
    }))
  }
  
  loadStore(){
    reaction(()=>this.pageSelectedCategory.categoryId,(curr,prev)=>{
      if(curr!=prev){
        this.fetchCategoryAthlets(curr,new PageFilter({page:1,take:3}),(data)=>{this.pageAthletContent=data});
        this.questionService.getQuestionsByCategory(curr)
        .pipe(
          map(r=>r.data as QuestionAnswerWebDto[]),
          map(r=>r.map(QuestionRestToViewModel))
        )
        .subscribe(response=>runInAction(()=>{
          this.pageAthletCategoryLastAnsweredQuestions = response;
        }));
      }
    })
  }

  private fetchCategoryAthlets(category:string,filter:PageFilter,onResponse:(atlethData:AthletView[])=>void){
    this.athletService
          .getAthletsByCategory(category,filter)
          .pipe(
            map(r=>r.data as AthletWebDto[]),
            map(r=>{
              return r.map(item=>{
                let viewModel:AthletView={
                  categoryId:item.categoryID,
                  id: item.id,
                  name: item.fullName,
                  avatarImgUrl: item.photoURL,
                  categoryName: this.pageSelectedCategory.name,
                  socialMedia: item.socialMedia
                };
                return viewModel;
              })
            })
          )
          .subscribe((response)=>runInAction(()=>onResponse(response)))
  }
}