import FormInputModel from "../FormInputModel";
import IFormInputModel, { FormInputs } from "../IFormInputModel";

class GiftValidationFormModel extends FormInputModel implements IFormInputModel {
  
  emptyValidator=(value:string,keyName:string)=>{
    let errors: string[] = [];
            if (value.trim().length < 2) {
              errors.push(`${keyName} alanı boş bırakılamaz`);
            }
    return { invalid: errors.length > 0, errors };
  }

  addValidationDynamic(value:string, key:string, type:string){
    if(this.formLabel.validation[key]) return;
    this.formLabel.validation[key]=(value)=>this.emptyValidator(value,key);
    if(type == "PhoneNumber"){
      this.formLabel.validation[key]=(value)=>{
        let errors: string[] = [];
        if (value === "" || value.length < 14) {
          errors.push(`${key} bilgisi boş bırakılamaz`);
        }
        return { invalid: errors.length > 0, errors };
      }
    }
  }
  
  constructor() {
    super({
      fieldKeys: [

      ],
      formLabels: {
        data: {
          
        },
        requiredKeys:{

        },
        validation: {
          //Adın:(value)=>this.emptyValidator(value,"Adın"),
          //Soyadın:(value)=>this.emptyValidator(value,"Soyadın"),
          /*
          "Mobile Phone":(value)=>{
            debugger;
            let errors: string[] = [];
            if (value === "" || value.length < 14) {
              errors.push("Cep telefonu bilgisi boş bırakılamaz");
            }
            return { invalid: errors.length > 0, errors };
          }*/
        },
      },
      defaultFormInputValues: {
      },
    });
    this.initForm();
  }

  


}

export default GiftValidationFormModel;
