import {
  action,
  makeAutoObservable,
  makeObservable,
  reaction,
  runInAction,
} from "mobx";
import { of, Subject, Subscription, throttleTime } from "rxjs";
import { Env } from "../../../env";
import  { AuthUserIntercom } from "../../utils/Intercom";
import { RootContextWrapper } from "../RootContext";

export default class MarketingAutomation {
  /**
   *
   */
  private context: RootContextWrapper;
  private _userProfileFetchStream: Subject<any> = new Subject();
  private _userProfileFetchSub: Subscription = of().subscribe();
  public booted: boolean = false;
  constructor(context: RootContextWrapper) {
   
    makeAutoObservable(this, {}, { autoBind: true });
    this.context = context;
  }
  initStore() {
    this._userProfileFetchSub.unsubscribe();
    this._userProfileFetchSub = this._userProfileFetchStream
      .pipe(throttleTime(500000)).subscribe(()=>{
        this.context.userStore.loadProfileData();
    })
    this._userProfileFetchStream.next({});
  }

  triggerMessenger() {
    //intercom.showMessenger();
    runInAction(() => {
      this.context.intercomEvents.show();
      this.context.intercomEvents.update();
    })
  }

  runIntercomMessenger(show: boolean = false) {
    runInAction(() => {
      let intercomRequest: AuthUserIntercom = {
        appId: Env.marketing.intercom.app_id,
        apiBase: Env.marketing.intercom.api_base,
        email: `${this.context.identityStore.userId}@${window.location.hostname}`,
        name: this.context.userStore.userProfile.firstName,
        userId: this.context.identityStore.userId,
        userHash: this.context.identityStore.userHmac
      };
      if (this.context.identityStore.isUserAuthenticated && intercomRequest.userId.length > 0) {
        //intercom.bootIntercom(intercomRequest);
        this.booted=true;
        this.context.intercomEvents.boot(intercomRequest);
        
      }
    })
  }
}
