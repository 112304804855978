import { makeAutoObservable } from "mobx";
import { SurveyViewCard } from "../../model/view/survey/SurveyView";
import SurveyRestService from "../../services/implementation/rest/app/SurveyRestService";
import ISurveyService, { SurveyWebDto } from "../../services/interface/app/ISurveyService";
import { RootContextWrapper } from "../RootContext";

export default class SurveyStore {
  surveyService: ISurveyService = new SurveyRestService(
    this.rootContext.identityStore
  );

  surveyPageContent: SurveyViewCard[] = [];

  constructor(private rootContext: RootContextWrapper) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  loadSurveys() {
    this.surveyService
      .getSurveys({ page: 1, take: 50 })
      .subscribe((response) => {
        this.surveyPageContent = (response.data as SurveyWebDto[]).map((a) => {
          return {
            completed: a?.isCompleted,
            description: a?.description,
            header: a?.title,
            surveyId: a?.id,
            redirectRelativeUrl:a?.redirectURL,
            previewImgUrl:a.photoURL
          } as SurveyViewCard;
        });
      });
  }

  
}
