import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import IdentityStore from "../../../../store/user/IdentityStore";
import ISurveyService, { SurveyWebDto } from "../../../interface/app/ISurveyService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";

export default class SurveyRestService
  extends ServiceBase
  implements ISurveyService
{
  /**
   *
   */
  constructor(identityStore: IdentityStore) {
    super(identityStore);
  }
  getSurveys(
    pagination?: PageFilter
  ): Observable<ServiceResponse<SurveyWebDto[]>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<SurveyWebDto[]>>({
      url: `${Env.services.api.host}/API/Survey/v7/GetSurveys?Page=${
        pagination?.page ?? 1
      }&Take=${pagination?.take ?? 9}`,
      method: "GET",
      headers
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }

}
