import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { Env } from "../../../../../env";
import { RootContextWrapper } from "../../../../store/RootContext";
import ILeaderService, {
  LeaderBoardResponseWebDto,
} from "../../../interface/app/ILeaderService";
import { PageFilter, ServiceResponse } from "../../../interface/IServiceBase";
import ServiceBase, { RestResponse } from "../ServiceBase";
import IdentityStore from "../../../../store/user/IdentityStore";

export default class LeaderRestService
  extends ServiceBase
  implements ILeaderService
{
  /**
   *
   */
  constructor(identityStore: IdentityStore) {
    super(identityStore);
  }
  getWeeklyLeaders(filter?: PageFilter | undefined): Observable<ServiceResponse<LeaderBoardResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<LeaderBoardResponseWebDto>>({
      url: `${Env.services.api.host}/API/Leaderboard/v7/GetWeekly?${
        filter && filter.toString && filter.toString()
      }`,
      method: "GET",
      headers,
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
  getMonthlyLeaders(
    filter?: PageFilter
  ): Observable<ServiceResponse<LeaderBoardResponseWebDto>> {
    let headers = this.getBaseHeaders();
    return ajax<RestResponse<LeaderBoardResponseWebDto>>({
      url: `${Env.services.api.host}/API/Leaderboard/v7/GetMonthly?${
        filter && filter.toString && filter.toString()
      }`,
      method: "GET",
      headers,
    }).pipe(
      (req) => this.handleConnectionRetry(req),
      (req) => this.mapToResponse(req)
    );
  }
}
